export const resources = {
  it: {
    translation: {
      "IscrizioneARegistroDellePersoneGiuridiche": "Iscrizione A Registro Delle Persone Giuridiche",
      "iscrizioneARegistroImprese": "Iscrizione A Registro Imprese",
      "numeroIscrizione":"Numero Iscrizione",   
      "datiSocietari":"Dati Societari",
      "nomeAlbo":"Nome Albo",
      "alboIvass":"IVASS",
      "alboCovip": "COVIP",
      "alboConsob": "CONSOB",
      "alboBancaItalia": "BANCA D'ITALIA",
      "selectedIscrizione":"Indicare se iscrizione:",
      "numeroIscrizioneAlbo": "n.ro Iscrizione",
      "nomeAlbo": "Nome Albo:",
      "seIscrittoAlbo": "Se è iscritto ad un Albo, specificare:",
      "autorita": "Autorità:",
      "numeroIscrizione": "n.ro Iscrizione :",
      "dataIscrizioneAlbo": "Data di iscrizione :",
      "sedeStato": "Sede prevalente attività svolta Stato ",
      "statoSedeAttivita": "Sede prevalente attività svolta Stato ",
      "numeroMandatoFid": "N. Mandato Fiduciario",
      "pf":"PF",
      "pg":"PG",
      "datiMandatoFiduciario": "Dati Mandato Fiduciario",
      "durataRapportoContrInt": "Da quanto è attivo il rapporto tra contraente e intermediario",
      "flOperazioneCoerente": "L'intermediario attesta che l'operazione è coerente rispetto al profilo del contraente",
      "profiloDiRischio": "Profilo di rischio(per i distributori che attribuiscono al contraente un profilo di rischio) :",
      "titolareEffQualifica": "Qualifica:",
      "benDiversoImp": "Se è stato scelto un Beneficiario diverso dall'impresa stessa, specificarne il motivo",
      "titolareEffBeneficiario": "Titolare Effettivo Beneficiario",
      "tabHeaderTitolareEffBenef": "Lista Riepilogo - Titolari Effettivi Beneficiari",
      "titolareEffBenefMorte": "Titolare Effettivo Beneficiario Morte",
      "titolareEffBenefVita": "Titolare Effettivo Beneficiario Vita",
      "relazioneTitolareContrMorte": "Relazione Contraente/Titolare effettivo",
      "relazioneTitolareContrVita": "Relazione Contraente/Titolare effettivo",
      "corrispondenzaDiversoResidenza": "Indirizzo di corrispondenza diverso da residenza /sede legale:",
      "corrispondenzaIntestatoContraente": "Indirizzo di corrispondenza intestato al contraente:",
      "intermediarioBancario": "In caso di sottoscrizione presso intermediario Bancario, se origine diversa da Reimpiego, si chiede di specificare il tempo intercorso tra l'afflusso della provvista presso la banca e la data pagamento premio",
      "general.classeBeneficiari": "Se il cliente, è un'organizzazione no profit, indicare la classe dei beneficiari a cui si rivolgono le attività svolte",
      "general.motivoDellaRelazione": "Motivo della relazione",
      "general.altro": "Altro",
      "general.AltroAttPrevAttSpecificare": "Altro (Specificare):",
      "general.seAltroSpecificare":"Se altro, specificare:",     
      "general.luogo": "Luogo",
      "general.data": "Data",
      "general.cognome": "Cognome",
      "general.nome": "Nome",
      "general.nominativo": "Nominativo",
      "general.collocatore": "Collocatore",
      "general.operazioneInCorso": "Operazione in Corso....",
      "general.verificaCodFiscInCorso": "Verifica Codice Fiscale in Corso....",
      "contraente": "Contraente",
      "assicurato": "Assicurato",
      "esecutore": "Soggetto Terzo", 
      "soggettoTerzoError": "E' obbligatorio definire il soggetto terzo",
      "beneficiarioVita": "Beneficiario Vita",
      "beneficiarioMorte": "Beneficiario Morte",
      "benefVita": "Benef. Vita",
      "benefMorte": "Benef. Morte",      
      "beneficiarioCedola": "Beneficiario Cedola",
      "beneficiarioGenerico": "Beneficiario Generico",
      "rappresentanteLegale": "Rappresentante Legale",
      "titolareEffettivo": "Titolare Effettivo Del Contraente",
      "referenteTerzo": "Referente Terzo",
      "referenteTerzoFacoltativo": "Referente Terzo (Facoltativo)",
      "dettaglioContraente": "Dettaglio Contraente",
      "sesso": "Sesso",
      "maschio": "Maschio",
      "femmina": "Femmina",
      "codiceFiscale": "Codice Fiscale",
      "partitaIVA": "Partita IVA",
      "percBeneficiario": "% Beneficiario",
      "percOwnership": "% di Ownership",
      "ragioneSociale": "Ragione Sociale",
      "dataCostituzione": "Data di Costituzione",  
      "dataNascita": "Data di Nascita", 
      "statoNascita": "Stato di Nascita",     
      "provinciaNascita": "Provincia di Nascita",  
      "comuneNascita": "Comune di Nascita",       
      "esisteEsecutore": "Esiste Soggetto Terzo",
      "esisteReferenteTerzo": "Esiste Referente Terzo",
      "si": "SI", 
      "no": "NO",      
      "relazContrEsecut": "Relazione Contraente/Soggetto Terzo",
      "relazioneContrTitEff": "Relazione Contraente/Titolare effettivo",
      "relazContrAss": "Relazione Contraente/Assicurato",
      "relazContrBenefVita": "Relazione tra Contraente e Beneficiario caso vita",
      "relazContrBenefMorte": "Relazione tra Contraente e Beneficiario caso morte",
      "motivoDesignazione": "Motivo della designazione di tale Assicurando",
      "tipoRappresentanza": "Indicare il tipo di Rappresentanza",
      "nucleoFam": "Nucleo familiare",          
      "ilPresenteEsecutore": "Il presente soggetto terzo è munito di rappresentaza?", 
      "munitoDelega": "SI",  
      "privoDelega": "NO",  
      "datiGenerali":"Dati Generali", 
      "datiGeneraliEsecutore": "Dati Generali del Soggetto terzo",
      "datiDiResidenza":"Dati di Residenza",
      "datiDiResidenzaGiuridico":"Dati della Sede Legale",
      "datiDiResidenzaEsecutore": "Dati Residenza del Soggetto terzo",
    //2020-12-01 PFD: SAV 'TO BE'      "datiDiRecapito":"Dati di Recapito",
      "indirizzoDiCorrispondenza":"Indirizzo di Corrispondenza",
      "datiDiRecapitoEsecutore": "Dati di Recapito del Soggetto terzo",
      "datiDiDomicilioEsecutore": "Dati di Domicilio del Soggetto terzo",
      "datiDiDomicilioTitolareEff": "Dati di Domicilio del Titolare Effettivo",
      "datiDiDomicilioContrFis": "Dati di Domicilio",
      "datiDiDomicilioRappLegale": "Dati di Domicilio del Rappresentante Legale",
      "domicilioDiversoResidenza": "Domicilio diverso da Residenza",
      "datiAntiriciclaggio":"Dati Antiriciclaggio",
      "datiAntiriciclaggioRappLegale":"Dati Antiriciclaggio del Rappresentante Legale", 
      "datiAntiriciclaggioTitolareEff":"Dati Antiriciclaggio del Titolare Effettivo",
      "datiAntiriciclaggioEsecutore": "Dati Antiriciclaggio del Soggetto terzo",
      "datiConsensoComunicazioneElettronica":"Consenso alla trasmissione della documentazione in formato elettronico",
      "consensoComunicazioneElettronica":"Consenso alla trasmissione in formato elettronico della documentazione relativa al contratto",
      "stato":"Stato",
      "provincia":"Provincia",
      "comune":"Comune",
      "toponimo":"Via/Piazza",
      "indirizzo":"Indirizzo",
      "numCivico":"Num.Civico",
      "cap":"CAP",
      "presso": "Presso",
      "cittadinanza1":"Cittadinanza 1",
      "cittadinanza2":"Cittadinanza 2",
      "numTelefono":"Telefono",
      "cellulare":"Cellulare",
      "datiAttivitaEconomica":"Dati Attività Economica",
      "datiAttivitaEconomicaRappLegale": "Dati Attività Economica Rappresentante Legale",
      "datiAttivitaEconomicaEsecutore": "Dati Attività Economica del Soggetto terzo",
    //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  "datiSituazioneFinanziaria": "Dati Situazione Finanziaria (da non compilare per il prodotto Taboo)",
      "datiSituazioneFinanziaria": "Dati Situazione Finanziaria",
      "fonteReddito": "Fonte Del Reddito",
      "redditoAnnuo": "Reddito Annuo",
    //2020-12-01 PFD: SAV 'TO BE'      "primaCasaContraente": "Prima Casa Di Proprieta",
    //2020-12-01 PFD: SAV 'TO BE'"altriImmobiliContraente": "Altri Immobili Di Proprieta",
      "patrimonioFinanziario": "Patrimonio Finanziario Inclusa La Liquidita",
      "impegnoFinanziario": "Impegni Finanziari Mensili",
    //2020-12-01 PFD: SAV 'TO BE'      "strumentiMercMon": "Strumenti Mercato Monetario",
    //2020-12-01 PFD: SAV 'TO BE'      "strumentiObbl": "Strumenti Obbligazionari",
    //2020-12-01 PFD: SAV 'TO BE'      "strumentiAzionari": "Strumenti Azionari / Fondi Investimento",
    //2020-12-01 PFD: SAV 'TO BE'      "strumentiAssicurativi": "Strumenti Assicurativi",
    //2020-12-01 PFD: SAV 'TO BE'      "strumentiComplessi": "Strumenti Complessi (Es. Fondi Alt.)",
    //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  "datiSituazioneFinanziariaGiuridico": "Dati Situazione Finanziaria (da non compilare per il prodotto Taboo)",
      "datiSituazioneFinanziariaGiuridico": "Dati Situazione Finanziaria",
      "sediSocContrGiuridico": "Sedi Della Società di Proprietà",
    //2020-12-01 PFD: SAV 'TO BE'      "inveImmoContrGiuridico": "Investimenti Immobiliari, indicare se:",
    //2020-12-01 PFD: SAV 'TO BE'      "altriImmobiliContraenteGiuridico": "Altri Immobili Di Proprietà",
      "fatturatoAnnuo": "Indicare Il Fatturato Annuo:",
      "prevalAttSvolta":"Att. Preval. Svolta",
      "provAttPreval":"Provincia Att. Preval.",
      "comuneAttPreval":"Comune Att. Preval.",
      "capAttPreval":"CAP Att. Preval.",
      "sae":"SAE",
      "ateco":"ATECO",
      "attivitaPrecedente": "Attività precedente",
      "ambitoAttivita": "Ambito Attività",
      "titoloStudio": "Istruzione",
      "altroTitoloStudio": "Descrizione Titolo di Studio",
      "datiFiscali":"Dati Fiscali",
      "datiFiscaliTitolareEff":"Dati Fiscali del Titolare Effettivo",
      "tasseStatiUniti":"Paga le tasse negli Stati Uniti d'America/US Territories",
      "tinGiin":"TIN / GIIN",
      "residenzaFiscale":"Ha la Residenza Fiscale in uno Stato diverso dall'Italia",
      "statoResidenzaFiscale1":"Stato Residenza Fiscale 1",
      "statoResidenzaFiscale2":"Stato Residenza Fiscale 2",
      "nifResidenzaFiscale1":"NIF Residenza Fiscale 1",
      "nifResidenzaFiscale2":"NIF Residenza Fiscale 2",
      "documentoRiconoscimento":"Documento di Riconoscimento",
      "tipoDocumento":"Tipo Documento",
      "numDocumento":"Num. Documento",
      "enteRilascio":"Ente Rilascio",
      "dataRilascio":"Data Rilascio",
      "dataScadenza":"Data Scadenza",
      "statoRilascio":"Stato Rilascio",
      "provRilascio":"Provincia Rilascio",
      "comuneRilascio":"Comune Rilascio",
      "persEspostoPoliticamente":"Persona Esposta Politicamente",
      "persEspostoPoliticamenteInCarica": "Indicare se in Carica",
      "caricaPEP": "Indicare carica ricoperta",
      "caricaPubblicaNoPEP":"Altre cariche pubbliche diverse da PEP",
      "scopoNaturaRapportoOrigineFondi":"Scopo e Natura del Rapporto e Origine dei Fondi",
      "scopoNaturaRapportoOrigineFondiTitolareEff": "Scopo e Natura del Rapporto e Origine dei Fondi del Titolare Effettivo",
      "scopoNatura":"Scopo e Natura",
      "stipulatoPerConto": "Il contratto è stipulato per conto proprio o per conto dell’assicurato (Rif. Contratto per conto altrui art. 1891 codice civile):",
      "scopoNaturaContratto": "Scopo e Natura Contratto",
    //2020-12-01 PFD: SAV 'TO BE'      "labelRipartizioneInvestimenti": "Ripartizione indicativa degli investimenti finanziari (dati facoltativi)",
      "selezioneIntermediarioBancario": "Ricerca tempo intercorso tra afflusso provvista e la data pagamento premio",
      "selezioneStatoProvenienzaSomme": "Specificare il paese di provenienza delle somme (se diverso da Italia)",
      "labelOrigineFondi": "Specificare l'origine dei fondi: (è possibile selezionare fino a due risposte)",
      "soggettiDaTutelare": "Soggetti da Tutelare",
      "origineFondi":"Origine Fondi",
      "comboRicerca": "Ricerca",
      "datiAnagrafici": "Dati Anagrafici",
      "indEmail": "Ind. Email",
      "indEmailCert": "Ind. Email Certificata",
      "presentiTitolariEffettivi": "Sono presenti Titolari Effettivi",
      "tipoSocieta": "Tipo Società",
      "entePubblico": "Ente Pubblico",
      "societaQuotata": "Società Quotata",
      "commerciale": "Commerciale",
      "fdoPensione": "F.do Pensione",
      "fiduciaria": "Fiduciaria",
      "intermediarioFinanziario": "Intermediario Finanziario",
      "contraenteAssicurato": "Contraente / Assicurato",
      "assicuratoUgualeContraente": "L'Assicurato coincide con il Contraente",
      "valutazioneAiSensiLgs": "Valutazione ai sensi dell’D. Lgs. 231/2007  e successive modifiche", 
/*20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
      "reticenzaFonireInfo": "Reticenza nel fonire le informazioni richieste",
      "pocaTrasparenzaComp": "Poca trasparenza della compagine societaria al fine di identificare il titolare effettivo",
      "inusualitaTransazione": "Inusualità della transazione e/o delle modalità operative o della tempistica",
      "interposizioneTerzi": "L’interposizione di terzi senza alcuna motivazione commerciale apprezzabile",
      "comportamentoNonDenotaAnomalie": "Comportamento che non denota anomalie", */
      "dichiarazioniDatiSoggetto": "Dichiarazioni e dati del soggetto Incaricato al collocamento",
      "regioneAttivita": "Regione Attività",
      "codiceIntermediario": "Codice Intermediario",
      "codiceRUI": "Codice RUI",
      "altreFigure": "Altre Figure",
      "caratteristicheContratto": "Caratteristiche Contratto",
      "consenso": "Consenso",
      "consensoTrattamentoDati": "Consenso al trattamento dei dati", 
      "testo.privacy1": "1. Dichiara/no di prendere atto che i suoi dati personali  ivi inclusi i dati relativi alla salute - saranno trattati dalla Società per le finalità di cui al punto 2 a) dell'Informativa medesima (finalità contrattuali e di legge).Inoltre, il Contraente, qualora soggetto diverso dall'Assicurando, dal Beneficiario e dal Referente Terzo, dichiara di provvedere a rendere noti i contenuti dell'Informativa all'Assicurando/Beneficiario/Referente Terzo alla prima occasione di contatto con quest'ultimo/i.",
      "testo.privacy2": "2. Per finalità di marketing, invio di offerte promozionali, iniziative commerciali dedicate, inviti alla partecipazione a concorsi a premi, materiale pubblicitario e vendita di prodotti o servizi della Società o di altre Società del Gruppo Zurich Insurance Group Ltd (tramite strumenti automatizzati quali ad esempio fax, sms, posta elettronica etc e strumenti tradizionali quali posta e telefono) nonché per consentire alla Società di condurre ricerche di mercato.",
      "testo.privacy3": "3. Per consentire alla Società di effettuare rilevazioni statistiche, al fine di migliorare i propri prodotti e servizi.",
      "testo.privacy4": "4. Per la comunicazione degli stessi a soggetti terzi come indicati al punto 2 lett. d) dell'Informativa, i quali potranno a loro volta trattarli per loro proprie finalità di marketing, invio di comunicazioni commerciali e vendita diretta tramite posta, posta elettronica, telefono, fax e qualsiasi altra tecnica di comunicazione a distanza, automatizzata e non automatizzata.",
      "dichiarazioniConsensi": "Dichiarazioni Consensi",
      "dichiarazioneIntermediario": "Dichiarazioni Intermediario",
      "questionari": "Questionari",
      "stampaProposta": "Stampa Proposta",
      "convalidaStampaDefinitiva": "Convalida e Stampa Definitiva",
      "nuovaProposta": "Nuova Proposta",
      "questionario": "Questionario",
      "Risparmio": "Risparmio",
      "Previdenza": "Previdenza",
      "Protezione": "Protezione",
      "Investimento": "Investimento",    
      "premio": "Premio",    
      "ripartizionePremio": "Ripartizione Premio",    
      "opzioniContrattuali": "Opzioni Contrattuali",    
      "modalitaPagamento": "Modalità di Pagamento",    
      "premioUnico": "Premio Unico",       
      "importoPremioUnicoIniziale_20K": "(Importo del premio Unico Iniziale minimo Euro 20.000,00)", 
      "importoPremioUnicoIniziale_30K": "(Importo del premio Unico Iniziale minimo Euro 30.000,00)",
      "classePremiPotenziale": "Classe di premi Potenziale",
      "variazioneCostiStandard": "Hai variato le condizioni standard del prodotto (es. commission sacrifice, bonus al cliente, special deals, deroghe costi) ?",
      "infoPropostaAppendice": "Ricordati di allegare alla proposta l’appendice di deroga sottoscritta dal cliente.",
      "classeA": "Classe A - per previsone di versamenti futuri fino a Euro 749.999,99", 
      "classeB": "Classe B - per previsone di versamenti futuri da Euro 750.0000,00 a 1.499.999,99", 
      "classeC": "Classe C - per previsone di versamenti futuri da Euro 1.500.0000,00 a 2.499.999,99", 
      "classeD": "Classe D - per previsone di versamenti futuri a partire da  Euro 2.500.0000,00", 
      "contraenteDichiaraPagatore": "Il contraente dichiara che il premio è pagato da", 
      "iban": "IBAN", 
      "banca": "Banca", 
      "agenzia": "Agenzia",
      "numeroAssegno1": "Numero Assegno 1",
      "numeroAssegno2": "Numero Assegno 2",
      "contoCointestato": "Conto Cointestato",
      "nomeCognomeCointestatario": "Cognome e Nome del Cointestatario",
      "relazPagatContr": "Relazione Pagatore/Contraente",
      "relazContrCoint": "Relazione Contraente/Cointestatario",
      "ripartizioneNonValida": "La somma delle ripartizioni finanziarie deve essere minore o uguale al patrimonio finanziario",
      "errorPensione": "Attività precedente al pensionamento non congruente",
      "nSottorubrica": "N. Sottorubrica",
      "intestatario": "Cognome e Nome dell'intestatario",
      "paeseBonifico": "Paese",
      "motivoContoEstero": "Motivo del conto Estero",
      "codiceSwift": "Codice SWIFT",
      "intestatarioBonificoEstero": "Cognome e Nome dell'intestatario",
      "intestatarioCarta": "Cognome e Nome dell'intestatario",
      "polizzaPolizze": "Polizza / Polizze",
      "modPagAltro": "Specificare la modalità di pagamento",
      "selezionareOpzioniContrattuali": "Selezionare una delle seguenti opzioni contrattuali. Non è possibile selezionare più opzioni su singolo contratto",
      "selezionarePianoSpostamento": "Selezionare una delle seguenti opzioni del piano di spostamento. Non è possibile selezionare più opzioni su singolo contratto",
      "pianoSpostamentoAutomatico": "PIANO SPOSTAMENTO AUTOMATICO",
      "allocazioneTarget": "Allocazione Target",
      "pianoErogazioniPeriodiche": "Piano Erogazioni Periodiche",
      "takeProfit": "Take Profit",
      "lifeCycle": "Life Cycle",
      "freqPrestazione": "Frequenza della Prestazione",
      "durata": "Durata",
      "percentuale": "Percentuale",
      "general.perc": "Perc. %",
      "percentualeObiettivo": "Percentuale Obiettivo",
      "legendaPianoErogazioniPeriodiche": "Il piano delle erogazioni periodiche è attivabile se la parte di premio destinata alla linea guidata/libera è pari almeno a 30.000 €",
      "legendaTakeProfit": "L’opzione take profit è attivabile a condizione che il premio versato sia pari almeno a 30.000 €",
      "legendaLifeCycle": "L’opzione life cycle è attivabile solo per la linea guidata Swing e per assicurati di età inferiore ai 55 anni",
      "allocazione": "Allocazione",
      "allocazioneMaxGestSep": "Allocazione massima in Gestione Separata così come indicato nelle condizioni contrattuali",
      "infoRibalanciamento": "In caso di Linea Libera indicare la soluzione con o senza Ribilanciamento:",
      "ribilanciamento": "Ribilanciamento",
      "codiceIsin": "Codice ISIN",
      "nomeFondo": "Nome Fondo",
      "percInvest": "% Inves.to",
      "note": "Note",
      "ordina": "Ordina",
      "datiRiepilogo" : 'Dati Riepilogo',
      "tabHeaderBenMorte" : 'Lista Riepilogo - Beneficiari Caso Morte',
      "tabHeaderBenVita" : 'Lista Riepilogo - Beneficiari Caso Vita',
      "tabHeaderTitEff" : 'Lista Riepilogo - Titolari Effettivi',
      "aggiungiBenefic": "Aggiungi Benefic.",
      "nuovoBenefic": "Nuovo Benefic.",
      "nuovoTitEff": "Nuovo Titolare Eff",
      "RelazioneContraente": "Relazione con Contraente",
      "nucleoFamiliare": "Nucleo Familiare",
      "benefEsclusoComunicaz": "Beneficiario escluso dall’invio di comunicazioni prima dell’evento",
      "noteFondi": "Nel caso in cui si scelga la linea libera, l’allocazione del premio può essere destinata da un minimo di 2 ad un massimo di 10 fondi con una % minima di investimento pari a 5%",
      "noteFondi2": "La somma delle % deve essere pari a 100%",
      "totale": "TOTALE",
      "questionarioIvass": "Questionario IVASS",
      "questionarioSanitario": "Questionario SANITARIO",
      "questionarioSanitarioSemplificato": "Questionario SANITARIO SEMPLIFICATO",
      "statoResidenza":"Stato di Residenza",
      "provinciaResidenza":"Provincia di Residenza",
      "comuneResidenza":"Comune di Residenza",
      "utente": "Utente",
      "password": "Password",
      "visuraCamerale" : "Visura Camerale",
      "allega" : "Allega:",
      "paesiBlackList": "L’attività di impresa include relazioni con i seguenti paesi ?",
      "entitaFinanzPassiva": "Il contraente è un’entità finanziaria passiva così come definita dal Decr. Min.18/10/2015 in attuazione della legge 18/06/2015 art 1-g e rif.successivi ?",
      "accountDimenticato": "Account dimenticato?",
	  "credenzialiDimenticate": "Hai dimenticato le tue credenziali di accesso?",	
      "nuovaPassword": "Nuova Password",
      "premioCapitaleAssicurato": "Premio & Capitale Assicurato", 
      "tariffa": "Tariffa",
      "edizioneModello": "Edizione Modello",
      "durataPagamentoPremi": "Durata Pag.Premi",
      "capitaleAssicurato": "Capitale Assicurato",
      "malattiaGrave": "Malattia Grave",
      "capitaleAssicuratoMalattiaGrave": "Capitale Assicurato Malattia Grave",
      "fasceCapitaleAmmesse": "(fasce di capitale ammesse: Euro 50.000,00 Euro 100.000,00 Euro 150.000,00)", 
      "complementareInfortuni": "Complementare Infortuni",
      "capitaleMassimoAssicurabile": "(capitale massimo assicurabile Euro 260.000,00)", 
      "frazionamento": "Frazionamento",    
      "infoLineeMultinvest": "Selezionare soltanto una delle linee Multinvest Extra. Non è possibile abbinare una linea guidata ad una linea libera",
      "infoLineeUnitLinked": "Selezionare soltanto una delle linee guidate. Non è possibile abbinare una linea guidata ad una linea libera",
      "lineeGuidate": "Linee Guidate",
      "isChangedFromSemplificatoAlert": "Devi compilare il questionario Sanitario",
      "questionarioAdeguato": "Questionario Adeguato",
      "questionarioNonAdeguato": "Questionario Non Adeguato",
//20210615      "premioRata": "Premio di rata",
      "premioRataVersSottoscrizione": "Premio di rata versato all'atto della sottoscrizione",
      "premioAnnuo": "Premio annuo di tariffa",
      "100": "Questionario anamnestico non idoneo - tariffa non selezionata",
      "101": "Questionario anamnestico non idoneo",               
   	  "102": "Questionario anamnestico non idoneo - rapporto peso altezza",                            
   	  "103": "Questionario anamnestico non idoneo - valori pressori fuori norma",                       
   	  "104": "Questionario anamnestico non idoneo - sigarette fumate >= 35", 
   	  "113": "Questionario anamnestico non idoneo - richiesta sovrappremi automatici",                  
   	  "212": "Questionario anamnestico non idoneo - copertura esclusiva mutuo o finanziamento",
   	  "307": "Questionario non compilato",
   	  "questionaIdoneo": "Questionario anamnestico compilato",      
   	  "questionaIdoneoSempl": "Questionario anamnestico semplificato compilato",      
   	  "beneficiarioEsteso": "Beneficiario Esteso", 
   	  "gestioneUtenze": "Gestione Utenze", 
   	  "nuovaUtenza": "Nuova Utenza", 
   	  "modificaUtenza": "Modifica Utenza", 
   	  "invioEmail": "Invio Email",
   	  "invia": "Invia",
      "datiValidi": "Salvataggio dati eseguito correttamente",
      "massimoTitEff": "Attenzione il numero massimo dei Titolari Effettivi è 4",
      "datiNonValidi": "Completare i dati richiesti (*) o evidenziati con errore",
   	  "errTotPercBenef": "La Percentuale Totale dei beneficiari deve essere uguale a 100%",
      "errTotPercTitEff": "La Percentuale Totale dei titolari non puo' essere superiore a 100%",
      "errorePercTotaleAllocazione": "La somma tra la percentuale destinata alla Gestione Separata e la percentuale destinata alla Linea di Investimento (guidata o libera) deve essere pari a 100%",
   	  "errorePercTotaleAllocazioneUL": "La percentuale destinata alla Linea di Investimento (guidata o libera) deve essere pari a 100%",
   	  "errorePercTotaleGestSep": "La percentuale destinata alla Gestione Separata non può superare il valore indicato nelle condizioni contrattuali",
      "errorePercTotaleFondo": "La somma delle percentuali di investimento sui singoli fondi deve essere 100%",
      "erroreFiduciante": "Almeno un titolare effettivo deve avere la qualifica di Fiduciante",
   	  "codiceConsulente": "Codice Consulente",
   	  "profilo": "Profilo",
   	  "dataCreazione": "Data Creazione",
   	  "mandato": "Mandato",
   	  "piazza": "Piazza",
   	  "dataUltimoAccesso": "Data Ultimo Accesso",
   	  "attezioneStampa": "ATTENZIONE: Procedendo con questa operazione i dati inseriti saranno convalidati e non potranno più essere modificati",
   	  "seguiSempliciOperazioni": "Segui quindi queste semplici operazioni:",
   	  "sempliceOperazioni_1": "1. Stampa e fai sottoscrivere la proposta ed i suoi allegati al cliente ", 
   	  "sempliceOperazioni_2": "2. Spedisci tutta la documentazione firmata in originale   presso gli uffici Zurich",
 //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  	  "sempliceOperazioni_3": "3. Al fine di ottemperare a quanto previsto dalla normativa antiriciclaggio si richiede di compilare il modulo SAV la cui compilazione è obbligatoria per tutti i prodotti a meno di Taboo",   	
   	  "anteprimaStampa": "Anteprima di Stampa",
   	  "anteprima": "Anteprima",
   	  "filtra": "Filtra",
   	  "dataBlocco": "Data Blocco",
   	  "dataRevoca": "Data Revoca",
   	  "statoUtenza": "Stato Utenza",
   	  "dataUltimaVariazione": "Data Ultima Variazione",
   	  "revoca": "Revoca",
   	  "modifica": "Modifica",
   	  "sblocca": "Sblocca",
   	  "riattiva": "Riattiva",
   	  "salva": "Salva",
   	  "salvaOk": "Salvataggio effettuato correttamente",
   	  "salvako": "Errore durante il salvataggio",
   	  "statoUtenteA": "ATTIVA",
   	  "statoUtenteP": "PENDENTE",
   	  "statoUtenteR": "REVOCATA",
   	  "statoUtenteB": "BLOCCATA",
   	  "statoUtenteS": "SCADUTA",
   	  "ricercaKo": "Nessun risultato trovato per i criteri di ricerca inseriti",
   	  "tornaRicerca": "Torna alla Ricerca",
   	  "logout": "Logout",
   	  "areaProdotti": "Area Prodotti",
   	  "tuoIdUtente": "Il tuo ID utente",
   	  "IdUtente": "ID utente",
   	  "tuaPassword": "La tua Password",
   	  "accedi": "Accedi",
   	  "tuaPasswordNuova": "La tua nuova Password",
   	  "confermaPassword": "Conferma la Password",
   	  "ERR_PWD_01": "Nuova password o conferma password non valorizzate",
   	  "ERR_PWD_02": "Nuova password e conferma password non coincidono",
   	  "ERR_PWD_03": "Nuova password deve differire dalla precedente",
   	  "ERR_PWD_04": "Vecchia password non corrisponde",
   	  "ERR_PWD_05": "Nuova password non formalmente corretta",
   	  "ERR_PWD_05_DET": "Lunghezza minima 8 caratteri, deve contenere almeno una lettera maiuscola, una minuscola, un numero ed un carattere speciale",
   	  "ERR_ULT_TEN": "Credenziali errate: resta un tentativo poi l’utenza verrà bloccata",
   	  "ERR_UTE_BLOCCATO": "Credenziali errate: account bloccato, contattare l’amministratore del sistema oppure selezionare l’azione nuova password",
   	  "ERR_UTE_REVOCATO" : "Account revocato, contattare l’amministratore del sistema",
   	  "ERR_EMAIL_NOT_FOUND": "Indirizzo e-mail non valido",
   	  "ERR_EMAIL_FOUND": "Indirizzo e-mail già presente",
   	  "ERR_USER_NOT_FOUND": "Utente non valido",
   	  "INF_SCAD_PWD": "Password in scadenza, vuoi modificarla?",
   	  "ERR_SCAD_PWD": "Password Scaduta",
   	  "ERR_GENERIC": "Errore generico accesso archivi",
   	  "ERR_PWD_99": "Errore generico",
   	  "ERR_LOGIN": "Credenziali errate",
   	  "operazioneOk": "Operazione eseguita con successo",
   	  "tuaEmailUtente": "La tua email utente",
   	  "copiaDaAltraFigura": "Copia Da Altra Figura Anagrafica",
   	  "tornaLogin": "Torna alla Login",
   	  "passwordDimenticata": "Credenziali Accesso Dimenticate",
   	  "modificaPassword": "Modifica Password",
   	  "inviaNuoveCredenziali": "Invia Nuove Credenziali",
      "cancellaDati": "Cancella Dati",
      "invioEmailTxt": "Procedendo  con questa operazione la proposta di assicurazione sarà inviata all’indirizzo di posta elettronica indicato dal contraente in proposta e all’indirizzo email associato all’utenza Z Semplifico",      
      "nessuna": "Nessuna",
      "emailInvioOk": "Email inviata correttamente",
      "elisaWeb": "Elisa Web",
      "moduloSav": "Modulo Sav",
      "relazPaesiBlackList": "Indicare i primi tre paesi con cui si ha relazione",
      "codCognomeNomeColloc": "Codice/Nome Cognome Collocatore", //2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario
      "dirittoObblioOncologico_1": "Diritto all’oblio oncologico (Legge 7 dicembre 2023, n. 193):",
      "dirittoObblioOncologico_2": "L’Assicurato ha il diritto di non fornire informazioni in merito a patologie oncologiche da cui è guarito da più di 10 anni (5 anni se la patologia è insorta prima dei 21 anni). Il Ministero della Salute ha definito per specifiche patologie termini ridotti (",
      "dirittoObblioOncologico_3": "vedasi Allegato 1 al Questionario",
      "dirittoObblioOncologico_4": ").",
      "dirittoObblioOncologico_5": "L’Assicurato, a tutte le domande che si riferiscono a patologie oncologiche di cui al questionario che segue, risponde “NO” quando:", 
      "dirittoObblioOncologico_6": "• non ne ha mai sofferto; o",
      "dirittoObblioOncologico_7": "• ne è guarito con terapia/trattamento attivo concluso nei termini sopra indicati, senza episodi di recidiva.",
      "allegato1_ALLEGATO": "ALLEGATO 1",
      "allegato1_DECRETO": "DECRETO 22 marzo 2024",
      "allegato1_Elenco": "Elenco di patologie oncologiche per le quali si applicano termini inferiori rispetto a quelli previsti dagli articoli 2, comma 1, 3, comma 1, lettera a), e 4, comma 1, della legge n. 193 del 2023. ",
      "allegato1_Tabella": "Tabella delle patologie per le quali è previsto un termine ridotto per il maturarsi dell’oblio oncologico rispetto al limite dei dieci anni (o cinque se diagnosi precedente al compimento del 21° anno di età) dalla fine del trattamento o dall’ultimo intervento chirurgico",
      "allegato1_Tabella_Header_1": "Tipo di tumore",
      "allegato1_Tabella_Header_2": "Specificazioni",
      "allegato1_Tabella_Header_3": "Anni dalla fine del trattamento",
      "allegato1_Tabella_R1_C1": "Colon-retto",
      "allegato1_Tabella_R1_C2": "Stadio I, qualsiasi età",
      "allegato1_Tabella_R1_C3": "1",
      "allegato1_Tabella_R2_C1": "Colon-retto",
      "allegato1_Tabella_R2_C2": "Stadio II-III, >21 anni",
      "allegato1_Tabella_R2_C3": "7",
      "allegato1_Tabella_R3_C1": "Melanoma",
      "allegato1_Tabella_R3_C2": ">21 anni",
      "allegato1_Tabella_R3_C3": "6",
      "allegato1_Tabella_R4_C1": "Mammella",
      "allegato1_Tabella_R4_C2": "Stadio I-II, qualsiasi età",
      "allegato1_Tabella_R4_C3": "1",
      "allegato1_Tabella_R5_C1": "Utero, collo",
      "allegato1_Tabella_R5_C2": ">21 anni",
      "allegato1_Tabella_R5_C3": "6",
      "allegato1_Tabella_R6_C1": "Utero, corpo",
      "allegato1_Tabella_R6_C2": "Qualsiasi età",
      "allegato1_Tabella_R6_C3": "5",
      "allegato1_Tabella_R7_C1": "Testicolo",
      "allegato1_Tabella_R7_C2": "Qualsiasi età",
      "allegato1_Tabella_R7_C3": "1",
      "allegato1_Tabella_R8_C1": "Tiroide",
      "allegato1_Tabella_R8_C2": "Donne con diagnosi <55 anni - uomini con diagnosi <45 anni. Esclusi i tumori anaplastici per entrambi i sessi ",
      "allegato1_Tabella_R8_C3": "1",
      "allegato1_Tabella_R9_C1": "Linfomi di Hodgkin",
      "allegato1_Tabella_R9_C2": "<45 anni",
      "allegato1_Tabella_R9_C3": "5",
      "allegato1_Tabella_R10_C1": "Leucemie	Acute (linfoblastiche e mieloidi)",
      "allegato1_Tabella_R10_C2": "qualsiasi età",
      "allegato1_Tabella_R10_C3": "5",
      "allegato1_Data": "Data di ultimo aggiornamento (pubblicazione in Gazzetta Ufficiale): 24 aprile 2024 ",
    }
  },
  en: {
    translation: {
      "IscrizioneARegistroDellePersoneGiuridiche": "Iscrizione A Registro Delle Persone Giuridiche_EN",
      "iscrizioneARegistroImprese": "Iscrizione A Registro Imprese_EN",
      "numeroIscrizione":"Numero Iscrizione_EN",
      "datiSocietari":"Dati Societari_EN",
      "nomeAlbo":"Nome Albo_EN",
      "alboIvass":"Ivass_EN",
      "alboCovip": "Covip_EN",
      "alboConsob": "Consob_EN",
      "alboBancaItalia": "Banca D'Italia_EN",
      "selectedIscrizione":"Indicare se iscrizione:_EN",
      "numeroIscrizioneAlbo": "n.ro Iscrizione_EN",
      "nomeAlbo": "Nome Albo:_EN",
      "seIscrittoAlbo": "Se è iscritto ad un Albo, specificare:_EN",
      "autorita": "Autorità:_EN",
      "numeroIscrizione": "n.ro Iscrizione :_EN",
      "dataIscrizioneAlbo": "Data di iscrizione :_EN",
      "sedeStato": "Sede prevalente attività svolta Stato_EN",
      "statoSedeAttivita": "Sede prevalente attività svolta Stato_EN",
      "numeroMandatoFid": "N. Mandato Fiduciario_EN",
      "pf":"PF_EN",
      "pg":"PG_EN",
      "datiMandatoFiduciario": "Dati Mandato Fiduciario_EN",
      "durataRapportoContrInt": "Da quanto è attivo il rapporto tra contraente e intermediario_EN",
      "flOperazioneCoerente": "L'intermediario attesta che l'operazione è coerente rispetto al profilo del contraente_EN",
      "profiloDiRischio": "Profilo di rischio(per i distributori che attribuiscono al contraente un profilo di rischio)_EN",
      "titolareEffQualifica": "Qualifica:_EN",
      "benDiversoImp": "Se è stato scelto un Beneficiario diverso dall'impresa stessa, specificarne il motivo_EN",
      "titolareEffBeneficiario": "Titolare Effettivo Beneficiario_EN",
      "tabHeaderTitolareEffBenef": "Lista Riepilogo - Titolari Effettivi Beneficiari_EN",
      "titolareEffBenef": "Titolare Effettivo Beneficiario Morte_EN",
      "titolareEffBenef": "Titolare Effettivo Beneficiario Vita_EN",
      "relazioneTitolareContrMorte": "Relazione Contraente/Titolare effettivo_EN",
      "relazioneTitolareContrVita": "Relazione Contraente/Titolare effettivo_EN",
      "corrispondenzaDiversoResidenza": "Indirizzo di corrispondenza diverso da residenza /sede legale:_EN",
      "corrispondenzaIntestatoContraente": "Indirizzo di corrispondenza intestato al contraente:_EN",
      "intermediarioBancario": "In caso di sottoscrizione presso intermediario Bancario, se origine diversa da Reimpiego, si chiede di specificare il tempo intercorso tra l'afflusso della provvista presso la banca e la data pagamento premio_EN",
      "general.classeBeneficiari": "Classe Beneficiari_EN",
      "general.motivoDellaRelazione": "Motivo della relazione_EN",
      "general.altro": "Altro_EN",
      "general.AltroAttPrevAttSpecificare": "Altro (Specificare):_EN",
      "general.seAltroSpecificare":"Se altro, specificare:_EN",
      "general.luogo": "Luogo_EN",
      "general.data": "Data_EN",
      "general.cognome": "Cognome_EN",
      "general.nome": "Nome_EN",
      "general.nominativo": "Nominativo_EN",
      "general.collocatore": "Collocatore_EN",
      "general.operazioneInCorso": "Operazione in Corso....EN",
      "general.verificaCodFiscInCorso": "Verifica Codice Fiscale in Corso...._EN",
      "contraente": "Contraente_EN",
      "assicurato": "Assicurato_EN",
      "esecutore": "Soggetto Terzo_EN", 
      "soggettoTerzoError": "E' obbligatorio definire il soggetto terzo_EN",
      "beneficiarioVita": "Beneficiario Vita_EN",
      "beneficiarioMorte": "Beneficiario Morte_EN",
      "benefVita": "Benef. Vita_EN",
      "benefMorte": "Benef. Morte_EN",
      "beneficiarioCedola": "Beneficiario Cedola_EN",
      "beneficiarioGenerico": "Beneficiario Generico_EN",
      "rappresentanteLegale": "Rappresentante Legale_EN",
      "titolareEffettivo": "Titolare Effettivo Del Contraente_EN",
      "referenteTerzo": "Referente Terzo_EN",
      "referenteTerzoFacoltativo": "Referente Terzo (Facoltativo)_EN",
      "dettaglioContraente": "Dettaglio Contraente_EN",      
      "ragioneSociale": "Ragione Sociale_EN",
      "dataCostituzione": "Data di Costituzione_EN",
      "sesso": "Sesso_EN",
      "maschio": "Maschio_EN",
      "femmina": "Femmina_EN",
      "codiceFiscale": "Codice Fiscale_EN",
      "partitaIVA": "Partita IVA_EN",
      "percBeneficiario": "% Beneficiario_EN",
      "percOwnership": "% di Ownership_EN",
      "dataNascita": "Data di Nascita_EN",  
      "statoNascita": "Stato di Nascita_EN",     
      "provinciaNascita": "Provincia di Nascita_EN",  
      "comuneNascita": "Comune di Nascita_EN",     
      "esisteEsecutore": "Esiste Soggetto Terzo_EN",
      "esisteReferenteTerzo": "Esiste Referente Terzo_EN",
      "si": "SI_EN", 
      "no": "NO_EN",
      "relazContrEsecut": "Relazione Contraente/Soggetto Terzo_EN",   
      "relazioneContrTitEff": "Relazione Contraente/Titolare effettivo_EN",
      "relazContrAss": "Relazione Contraente/Assicurato_EN",
      "relazContrBenefVita": "Relazione tra Contraente e Beneficiario caso vita_EN",
      "relazContrBenefMorte": "Relazione tra Contraente e Beneficiario caso morte_EN",
      "motivoDesignazione": "Motivo della designazione di tale Assicurando_EN",
      "tipoRappresentanza": "Indicare il tipo di Rappresentanza_EN",
      "nucleoFam": "Nucleo familiare_EN",     
      "ilPresenteEsecutore": "Il presente soggetto terzo è munito di rappresentaza?_EN",  
      "munitoDelega": "SI_EN",  
      "privoDelega": "NO_EN",  
      "datiGenerali":"Dati Generali_EN",
      "datiGeneraliEsecutore": "Dati Generali del Soggetto terzo_EN",
      "datiDiResidenza":"Dati di Residenza_EN", 
      "datiDiResidenzaGiuridico":"Dati della Sede Legale_EN",
      "datiDiResidenzaEsecutore": "Dati Residenza del Soggetto terzo_EN",
    //2020-12-01 PFD: SAV 'TO BE'      "datiDiRecapito":"Dati di Recapito_EN",
      "indirizzoDiCorrispondenza":"Indirizzo di Corrispondenza_EN",
      "datiDiRecapitoEsecutore": "Dati di Recapito del Soggetto terzo_EN",
      "datiDiDomicilioEsecutore": "Dati di Domicilio del Soggetto terzo_EN",
      "datiDiDomicilioTitolareEff": "Dati di Domicilio del Titolare Effettivo_EN",
      "datiDiDomicilioContrFis": "Dati di Domicilio_EN",
      "datiDiDomicilioRappLegale": "Dati di Domicilio del Rappresentante Legale_EN",
      "domicilioDiversoResidenza": "Domicilio diverso da Residenza_EN",
      "datiAntiriciclaggio":"Dati Antiriciclaggio_EN",
      "datiAntiriciclaggioRappLegale":"Dati Antiriciclaggio del Rappresentante Legale_EN",
      "datiAntiriciclaggioTitolareEff":"Dati Antiriciclaggio del Titolare Effettivo_EN",
      "datiAntiriciclaggioEsecutore": "Dati Antiriciclaggio del Soggetto terzo_EN",
      "datiConsensoComunicazioneElettronica":"Consenso alla trasmissione della documentazione in formato elettronico_EN",
      "consensoComunicazioneElettronica":"Consenso alla trasmissione in formato elettronico della documentazione relativa al contratto_EN",
      "stato":"Stato_EN",
      "provincia":"Provincia_EN",
      "comune":"Comune_EN",
      "toponimo":"Via/Piazza_EN",
      "indirizzo":"Indirizzo_EN",
      "numCivico":"Num.Civico_EN",
      "cap":"CAP_EN",
      "presso": "Presso_EN",
      "cittadinanza1":"Cittadinanza 1_EN",
      "cittadinanza2":"Cittadinanza 2_EN",
      "numTelefono":"Telefono_EN",
      "cellulare":"Cellulare",
      "datiAttivitaEconomica":"Dati Attività Economica_EN",
      "datiAttivitaEconomicaRappLegale": "Dati Attività Economica Rappresentante Legale_EN",
      "datiAttivitaEconomicaEsecutore": "Dati Attività Economica del Soggetto terzo_EN",
    //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  "datiSituazioneFinanziaria": "Dati Situazione Finanziaria (da non compilare per il prodotto Taboo)_EN",
      "datiSituazioneFinanziaria": "Dati Situazione Finanziaria",
      "fonteReddito": "Fonte Del Reddito_EN",
      "redditoAnnuo": "Reddito Annuo_EN",
      "primaCasaContraente": "Prima Casa Di Proprieta_EN",
      "altriImmobiliContraente": "Altri Immobili Di Proprieta_EN",
      "patrimonioFinanziario": "Patrimonio Finanziario Inclusa La Liquidita_EN",
      "impegnoFinanziario": "Impegni Finanziari Mensili_EN",
      "strumentiMercMon": "Strumenti Mercato Monetario_EN",
      "strumentiObbl": "Strumenti Obbligazionari_EN",
      "strumentiAzionari": "Strumenti Azionari / Fondi Investimento_EN",
      "strumentiAssicurativi": "Strumenti Assicurativi_EN",
      "strumentiComplessi": "Strumenti Complessi (Es. Fondi Alt.)_EN",
      //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  "datiSituazioneFinanziariaGiuridico": "Dati Situazione Finanziaria (da non compilare per il prodotto Taboo)",
      "datiSituazioneFinanziariaGiuridico": "Dati Situazione Finanziaria",
      "sediSocContrGiuridico": "Sedi Della Società di Proprietà_EN",
    //2020-12-01 PFD: SAV 'TO BE'      "inveImmoContrGiuridico": "Investimenti Immobiliari, indicare se:_EN",
      "altriImmobiliContraenteGiuridico": "Altri Immobili Di Proprietà_EN",
      "fatturatoAnnuo": "Indicare Il Fatturato Annuo:_EN",
      "prevalAttSvolta":"Att. Preval. Svolta_EN",
      "provAttPreval":"Provincia Att. Preval._EN",
      "comuneAttPreval":"Comune Att. Preval._EN",
      "capAttPreval":"CAP Att. Preval._EN",
      "sae":"SAE_EN",
      "ateco":"ATECO_EN",
      "titoloStudio": "Istruzione_EN",
      "altroTitoloStudio": "Descrizione Titolo di Studio_EN",
      "datiFiscali":"Dati Fiscali_EN",
      "datiFiscaliTitolareEff":"Dati Fiscali del Titolare Effettivo_EN",
      "tasseStatiUniti":"Paga le tasse negli Stati Uniti d'America/US Territories_EN",
      "tinGiin":"TIN / GIIN_EN",
      "residenzaFiscale":"Ha la Residenza Fiscale in uno Stato diverso dall'Italia_EN",
      "statoResidenzaFiscale1":"Stato Residenza Fiscale 1_EN",
      "statoResidenzaFiscale2":"Stato Residenza Fiscale 2_EN",
      "nifResidenzaFiscale1":"NIF Residenza Fiscale 1_EN",
      "nifResidenzaFiscale2":"NIF Residenza Fiscale 2_EN",
      "documentoRiconoscimento":"Documento di Riconoscimento_EN",
      "tipoDocumento":"Tipo Documento_EN",
      "numDocumento":"Num. Documento_EN",
      "enteRilascio":"Ente Rilascio_EN",
      "dataRilascio":"Data Rilascio_EN",
      "dataScadenza":"Data Scadenza_EN",
      "statoRilascio":"Stato Rilascio_EN",
      "provRilascio":"Provincia Rilascio_EN",
      "comuneRilascio":"Comune Rilascio_EN",
      "persEspostoPoliticamente":"Persona Esposta Politicamente_EN",
      "caricaPubblicaNoPEP":"Altre cariche pubbliche diverse da PEP_EN",
      "scopoNaturaRapportoOrigineFondi":"Scopo e Natura del Rapporto e Origine dei Fondi_EN",
      "scopoNaturaRapportoOrigineFondiTitolareEff":"Scopo e Natura del Rapporto e Origine dei Fondi del Titolare Effettivo_EN",
      "scopoNatura":"Scopo e Natura_EN",
      "stipulatoPerConto": "Il contratto è stipulato per conto proprio o per conto dell’assicurato (Rif. Contratto per conto altrui art. 1891 codice civile):_EN",
      "scopoNaturaContratto": "Scopo e Natura Contratto_EN",
    //2020-12-01 PFD: SAV 'TO BE'      "labelRipartizioneInvestimenti": "Ripartizione indicativa degli investimenti finanziari (dati facoltativi)_EN",
      "selezioneIntermediarioBancario": "Ricerca tempo intercorso tra afflusso provvista e la data pagamento premio_EN",
      "selezioneStatoProvenienzaSomme": "Specificare il paese di provenienza delle somme (se diverso da Italia)_EN",
      "labelOrigineFondi": "Specificare l'origine dei fondi: (è possibile selezionare fino a due risposte)_EN",
      "soggettiDaTutelare": "Soggetti da Tutelare_EN",
      "origineFondi":"Origine Fondi_EN",
      "comboRicerca": "Ricerca_EN",
      "datiAnagrafici": "Dati Anagrafici_EN",
      "indEmail": "Ind. Email_EN",
      "indEmailCert": "Ind. Email Certificata_EN",
      "presentiTitolariEffettivi": "Sono presenti Titolari Effettivi_EN",
      "entePubblico": "Ente Pubblico_EN",
      "societaQuotata": "Società Quotata_EN",
      "commerciale": "Commerciale_EN",
      "fdoPensione": "F.do Pensione_EN",
      "fiduciaria": "Fiduciaria_EN",
      "intermediarioFinanziario": "Intermediario Finanziario_EN",
      "contraenteAssicurato": "Contraente / Assicurato_EN",
      "assicuratoUgualeContraente": "L'Assicurato coincide con il Contraente_EN",
      "valutazioneAiSensiLgs": "Valutazione ai sensi dell’D. Lgs. 231/2007  e successive modifiche_EN",
/*20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
      "reticenzaFonireInfo": "Reticenza nel fonire le informazioni richieste_EN",
      "pocaTrasparenzaComp": "Poca trasparenza della compagine societaria al fine di identificare il titolare effettivo_EN",
      "inusualitaTransazione": "Inusualità della transazione e/o delle modalità operative o della tempistica_EN",
      "interposizioneTerzi": "L’interposizione di terzi senza alcuna motivazione commerciale apprezzabile_EN",
      "comportamentoNonDenotaAnomalie": "Comportamento che non denota anomalie_EN", */
      "dichiarazioniDatiSoggetto": "Dichiarazioni e dati del soggetto Incaricato al collocamento_En",
      "regioneAttivita": "Regione Attività_EN",
      "codiceIntermediario": "Codice Intermediario_EN",
      "codiceRUI": "Codice RUI_EN",
      "altreFigure": "Altre Figure_EN",
      "caratteristicheContratto": "Caratteristiche Contratto_EN",
      "consenso": "Consenso_EN",
      "consensoTrattamentoDati": "Consenso al trattamento dei dati", 
      "testo.privacy1": "1. Dichiara/no di prendere atto che i suoi dati personali  ivi inclusi i dati relativi alla salute - saranno trattati dalla Società per le finalità di cui al punto 2 a) dellInformativa medesima (finalità contrattuali e di legge).Inoltre, il Contraente, qualora soggetto diverso dall'Assicurando, dal Beneficiario e dal Referente Terzo, dichiara di provvedere a rendere noti i contenuti dell'Informativa all'Assicurando/Beneficiario/Referente Terzo alla prima occasione di contatto con quest'ultimo/i._EN",
      "testo.privacy2": "2. Per finalità di marketing, invio di offerte promozionali, iniziative commerciali dedicate, inviti alla partecipazione a concorsi a premi, materiale pubblicitario e vendita di prodotti o servizi della Società o di altre Società del Gruppo Zurich Insurance Group Ltd (tramite strumenti automatizzati quali ad esempio fax, sms, posta elettronica etc e strumenti tradizionali quali posta e telefono) nonché per consentire alla Società di condurre ricerche di mercato._EN",
      "testo.privacy3": "3. Per consentire alla Società di effettuare rilevazioni statistiche, al fine di migliorare i propri prodotti e servizi._EN",
      "testo.privacy4": "4. Per la comunicazione degli stessi a soggetti terzi come indicati al punto 2 lett. d) dell'Informativa, i quali potranno a loro volta trattarli per loro proprie finalità di marketing, invio di comunicazioni commerciali e vendita diretta tramite posta, posta elettronica, telefono, fax e qualsiasi altra tecnica di comunicazione a distanza, automatizzata e non automatizzata._EN",
      "dichiarazioniConsensi": "Dichiarazioni Consensi_EN",
      "dichiarazioneIntermediario": "Dichiarazioni Intermediario_EN",
      "questionari": "Questionari_EN",
      "stampaProposta": "Stampa Proposta_EN",
      "convalidaStampaDefinitiva": "Convalida e Stampa Definitiva_EN",   
      "nuovaProposta": "Nuova Proposta_EN",
      "questionario": "Questionario_EN",  
      "Risparmio": "Risparmio_EN",
      "Previdenza": "Previdenza_EN",
      "Protezione": "Protezione_EN",
      "Investimento": "Investimento_EN", 
      "premio": "Premio_EN",    
      "ripartizionePremio": "Ripartizione Premio_EN",    
      "opzioniContrattuali": "Opzioni Contrattuali_EN",    
      "modalitaPagamento": "Modalità di Pagamento_EN", 
      "premioUnico": "Premio Unico_EN",
      "importoPremioUnicoIniziale": "(Importo del premio Unico Iniziale minimo Euro 20.000,00)_EN", 
      "classePremiPotenziale": "Classe di premi Potenziale_EN", 
      "variazioneCostiStandard": "Hai variato le condizioni standard del prodotto (es. commission sacrifice, bonus al cliente, special deals, deroghe costi) ?_EN",
      "classeA": "Classe A - per previsone di versamenti futuri fino a Euro 749.999,99_EN", 
      "classeB": "Classe B - per previsone di versamenti futuri da Euro 7.0000,00 a 1.499.999,99_EN", 
      "classeC": "Classe C - per previsone di versamenti futuri da Euro 1.500.0000,00 a 2.499.999,99_EN", 
      "classeD": "Classe D - per previsone di versamenti futuri a partire da  Euro 2.500.0000,00_EN", 
      "contraenteDichiaraPagatore": "Il contraente dichiara che il premio è pagato da_EN", 
      "iban": "IBAN_EN", 
      "banca": "Banca_EN", 
      "agenzia": "Agenzia_EN", 
      "numeroAssegno1": "Numero Assegno 1_EN",
      "numeroAssegno2": "Numero Assegno 2_EN",
      "contoCointestato": "Conto Cointestato_EN",
      "nomeCognomeCointestatario": "Cognome e Nome del Cointestatario_EN",
      "relazPagatContr": "Relazione Pagatore/Contraente_EN",
      "relazContrCoint": "Relazione Contraente/Cointestatario_EN",
      "ripartizioneNonValida": "La somma delle ripartizioni finanziarie deve essere minore o uguale al patrimonio finanziario_EN",
      "errorPensione": "Attività precedente al pensionamento non congruente_EN",
      "nSottorubrica": "N. Sottorubrica_EN",
      "intestatario": "Cognome e Nome dell'intestatario_EN",
      "paeseBonifico": "Paese_EN",
      "motivoContoEstero": "Motivo del conto Estero_EN",
      "codiceSwift": "Codice SWIFT_EN",
      "intestatarioBonificoEstero": "Cognome e Nome dell'intestatario_EN",
      "intestatarioCarta": "Cognome e Nome dell'intestatario_EN",
      "polizzaPolizze": "Polizza / Polizze_EN",
      "modPagAltro": "Specificare la modalità di pagamento_EN",
      "selezionareOpzioniContrattuali": "Selezionare una delle seguenti opzioni contrattuali. Non è possibile selezionare più opzioni su singolo contratto_EN",
      "selezionarePianoSpostamento": "Selezionare una delle seguenti opzioni del piano di spostamento. Non è possibile selezionare più opzioni su singolo contratto_EN",
      "pianoSpostamentoAutomatico": "PIANO SPOSTAMENTO AUTOMATICO_EN",
      "allocazioneTarget": "Allocazione Target_EN",
      "pianoErogazioniPeriodiche": "Piano Erogazioni Periodiche_EN",
      "takeProfit": "Take Profit_EN",
      "lifeCycle": "Life Cycle_EN",
      "freqPrestazione": "Frequenza della Prestazione_EN",
      "durata": "Durata_EN",
      "percentuale": "Percentuale_EN",
      "general.perc": "Perc. %_EN",
      "percentualeObiettivo": "Percentuale Obiettivo_EN",
      "legendaPianoErogazioniPeriodiche": "Il piano delle erogazioni periodiche è attivabile se la parte di premio destinata alla linea guidata/libera è pari almeno a 30.000 €_EN",
      "legendaTakeProfit": "L’opzione take profit è attivabile a condizione che il premio versato sia pari almeno a 30.000 €_EN",
      "legendaLifeCycle": "L’opzione life cycle è attivabile solo per la linea guidata Swing e per assicurati di età inferiore ai 55 anni_EN",
      "allocazione": "Allocazione_EN",
      "allocazioneMaxGestSep": "Allocazione massima in Gestione Separata così come indicato nelle condizioni contrattuali_EN",
      "infoRibalanciamento": "In caso di Linea Libera indicare la soluzione con o senza Ribilanciamento:_EN",
      "ribilanciamento": "Ribilanciamento_EN",
      "codiceIsin": "Codice ISIN_EN",
      "nomeFondo": "Nome Fondo_EN",
      "percInvest": "% Inves.to_EN",
      "note": "Note_EN",
      "ordina": "Ordina_EN",
      "datiRiepilogo" : 'Dati Riepilogo_EN',
      "tabHeaderBenMorte" : 'Lista Riepilogo - Beneficiari Caso Morte_EN',
      "tabHeaderBenVita" : 'Lista Riepilogo - Beneficiari Caso Vita_EN',
      "tabHeaderTitEff" : 'Lista Riepilogo - Titolari Effettivi_EN',
      "aggiungiBenefic": "Aggiungi Benef._EN",
      "nuovoBenefic": "Nuovo Benefic._EN",
      "nuovoTitEff": "Nuovo Titolare Eff_EN",
      "noteFondi": "Nel caso in cui si scelga la linea libera, l’allocazione del premio può essere destinata da un minimo di 2 ad un massimo di 10 fondi con una % minima di investimento pari a 5%_EN",
      "noteFondi2": "La somma delle % deve essere pari a 100%_EN",
      "totale": "TOTALE_EN",
      "questionarioIvass": "Questionario IVASS_EN",
      "questionarioSanitario": "Questionario SANITARIO_EN",
      "questionarioSanitarioSemplificato": "Questionario SANITARIO SEMPLIFICATO_EN",
      "statoResidenza":"Stato di Residenza_EN",
      "provinciaResidenza":"Provincia di Residenza_EN",
      "comuneResidenza":"Comune di Residenza_EN",
      "utente": "Utente_EN",
      "password": "Password_EN",
      "tipoSocieta": "Tipo Società_EN",
      "visuraCamerale" : "Visura Camerale_EN",
      "allega" : "Allega:_EN",
      "paesiBlackList": "L’attività di impresa include relazioni con i seguenti paesi ?_EN",
      "entitaFinanzPassiva": "Il contraente è un’entità finanziaria passiva così come definita dal Decr. Min.18/10/2015 in attuazione della legge 18/06/2015 art 1-g e rif.successivi ?_EN",
      "accountDimenticato": "Account dimenticato?_EN",
	  "credenzialiDimenticate": "Hai dimenticato le tue credenziali di accesso?_EN",	
      "nuovaPassword": "Nuova Password_EN",
      "premioCapitaleAssicurato": "Premio & Capitale Assicurato_EN", 
      "tariffa": "Tariffa_EN",
      "edizioneModello": "Edizione Modello_EN",
      "durataPagamentoPremi": "Durata Pag.Premi_EN",
      "capitaleAssicurato": "Capitale Assicurato_EN",
      "malattiaGrave": "Malattia Grave_EN",
      "capitaleAssicuratoMalattiaGrave": "Capitale Assicurato Malattia Grave_EN",
      "fasceCapitaleAmmesse": "(fasce di capitale ammesse: Euro 50.000,00 Euro 100.000,00 Euro 150.000,00)_EN", 
      "complementareInfortuni": "Complementare Infortuni_EN",
      "capitaleMassimoAssicurabile": "(capitale massimo assicurabile Euro 260.000,00)_EN", 
      "frazionamento": "Frazionamento_EN", 
      "infoLineeMultinvest": "Selezionare soltanto una delle linee Multinvest Extra. Non è possibile abbinare una linea guidata ad una linea libera_EN",
      "infoLineeUnitLinked": "Selezionare soltanto una delle linee guidate. Non è possibile abbinare una linea guidata ad una linea libera_EN",
      "lineeGuidate": "Linee Guidate_EN",
      "isChangedFromSemplificatoAlert": "Devi compilare il questionario Sanitario_EN",
      "questionarioAdeguato": "Questionario Adeguato_EN",
      "questionarioNonAdeguato": "Questionario Non Adeguato_EN",
//20210615      "premioRata": "Premio di rata_EN",
      "premioRataVersSottoscrizione": "Premio di rata versato all'atto della sottoscrizione",
      "premioAnnuo": "Premio annuo di tariffa_EN",
      "100": "Questionario anamnestico non idoneo - tariffa non selezionata_EN",
      "101": "Questionario anamnestico non idoneo_EN",               
      "102": "Questionario anamnestico non idoneo - rapporto peso altezza_EN",                            
   	  "103": "Questionario anamnestico non idoneo - valori pressori fuori norma_EN",                       
   	  "104": "Questionario anamnestico non idoneo - sigarette fumate >= 35_EN", 
   	  "113": "Questionario anamnestico non idoneo - richiesta sovrappremi automatici_EN",                  
   	  "212": "Questionario anamnestico non idoneo - copertura esclusiva mutuo o finanziamento_EN",
   	  "307": "Questionario non compilato_EN",
       "questionaIdoneo": "Questionario anamnestico compilato_EN",   
       "questionaIdoneoSempl": "Questionario anamnestico semplificato compilato_EN",        
   	  "beneficiarioEsteso": "Beneficiario Esteso_EN",  
   	  "gestioneUtenze": "Gestione Utenze_EN",
   	  "nuovaUtenza": "Nuova Utenza_EN", 
 	  "modificaUtenza": "Modifica Utenza_EN", 
   	  "invioEmail": "Invio Email_EN",
   	  "invia": "Invia_EN",
      "datiValidi": "Salvataggio dati eseguito correttamente_EN",
      "massimoTitEff": "Attenzione il numero massimo dei Titolari Effettivi è 4_EN",
      "datiNonValidi": "Completare i dati richiesti (*) o evidenziati con errore_EN",
   	  "errTotPercBenef": "La Percentuale Totale dei beneficiari deve essere uguale a 100%_EN",
       "errTotPercTitEff": "La Percentuale Totale dei titolari non puo' essere superiore a 100%_EN",
       "errorePercTotaleAllocazione": "La somma tra la percentuale destinata alla Gestione Separata e la percentuale destinata alla Linea di Investimento (guidata o libera) deve essere pari a 100%_EN",
   	  "errorePercTotaleAllocazioneUL": "La percentuale destinata alla Linea di Investimento (guidata o libera) deve essere pari a 100%_EN",
   	  "errorePercTotaleGestSep": "La percentuale destinata alla Gestione Separata non può superare il valore indicato nelle condizioni contrattuali_EN",
   	  "errorePercTotaleFondo": "La somma delle percentuali di investimento sui singoli fondi deve essere 100%_EN",
       "erroreFiduciante": "Almeno un titolare effettivo deve avere la qualifica di Fiduciante_EN",
       "codiceConsulente": "Codice Consulente_EN",
   	  "profilo": "Profilo_EN",
   	  "dataCreazione": "Data Creazione_EN",
   	  "mandato": "Mandato_EN",
   	  "piazza": "Piazza_EN",
   	  "dataUltimoAccesso": "Data Ultimo Accesso_EN",
   	  "attezioneStampa": "ATTENZIONE: Procedendo con questa operazione i dati inseriti saranno convalidati e non potranno più essere modificati_EN",
	  "seguiSempliciOperazioni": "Segui quindi queste semplici operazioni:_EN",
   	  "sempliceOperazioni_1": "1. Stampa e fai sottoscrivere la proposta ed i suoi allegati al cliente_EN", 
   	  "sempliceOperazioni_2": "2. Spedisci tutta la documentazione firmata in originale   presso gli uffici Zurich_EN",
//2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP   	"sempliceOperazioni_3": "3. Al fine di ottemperare a quanto previsto dalla normativa antiriciclaggio si richiede di compilare il modulo SAV la cui compilazione è obbligatoria per tutti i prodotti a meno di Taboo_EN",
   	  "anteprimaStampa": "Anteprima di Stampa_EN",
   	  "anteprima": "Anteprima_EN",
   	  "filtra": "Filtra_EN",
   	  "dataBlocco": "Data Blocco_EN",
 	  "dataRevoca": "Data Revoca_EN",
 	  "statoUtenza": "Stato Utenza_EN",
 	  "dataUltimaVariazione": "Data Ultima Variazione_EN",
 	  "revoca": "Revoca_EN",
   	  "modifica": "Modifica_EN",
   	  "sblocca": "Sblocca_EN",
   	  "riattiva": "Riattiva_EN",
   	  "salva": "Salva_EN",
   	  "salvaOk": "Salvataggio effettuato correttamente_EN",
 	  "salvako": "Errore durante il salvataggio_EN",
 	  "statoUtenteA": "ATTIVA_EN",
 	  "statoUtenteP": "PENDENTE_EN",
  	  "statoUtenteR": "REVOCATA_EN",
  	  "statoUtenteB": "BLOCCATA_EN",
  	  "statoUtenteS": "SCADUTA_EN",
  	  "ricercaKo": "Nessun risultato trovato per i criteri di ricerca inseriti_EN",
  	  "tornaRicerca": "Torna alla Ricerca_EN",
  	  "logout": "Logout_EN",
  	  "areaProdotti": "Area Prodotti_EN",
  	  "tuoIdUtente": "Il tuo ID utente_EN",
 	  "IdUtente": "ID utente_EN",
 	  "tuaPassword": "La tua Password_EN",
 	  "accedi": "Accedi_EN",
 	  "tuaPasswordNuova": "La tua nuova Password_EN",
  	  "confermaPassword": "Conferma la Password_EN",
  	  "ERR_PWD_01": "Nuova password o conferma password non valorizzate_EN",
 	  "ERR_PWD_02": "Nuova password e conferma password non coincidono_EN",
 	  "ERR_PWD_03": "Nuova password deve differire dalla precedente_EN",
 	  "ERR_PWD_04": "Vecchia password non corrisponde_EN",
 	  "ERR_PWD_05": "Nuova password non formalmente corretta_EN",
  	  "ERR_PWD_05_DET": "Lunghezza minima 8 caratteri, deve contenere almeno una lettera maiuscola, una minuscola, un numero ed un carattere speciale_EN",
  	  "ERR_ULT_TEN": "Credenziali errate: resta un tentativo poi l’utenza verrà bloccata_EN",
 	  "ERR_UTE_BLOCCATO": "Credenziali errate: account bloccato, contattare l’amministratore del sistema oppure selezionare l’azione nuova password_EN",
 	  "ERR_UTE_REVOCATO" : "Account revocato, contattare l’amministratore del sistema_EN",
 	  "ERR_EMAIL_NOT_FOUND": "Indirizzo e-mail non valido_EN",
 	  "ERR_EMAIL_FOUND": "Indirizzo e-mail già presente_EN",
 	  "ERR_USER_NOT_FOUND": "Utente non valido_EN",
 	  "INF_SCAD_PWD": "Password in scadenza, vuoi modificarla?_EN",
 	  "ERR_SCAD_PWD": "Password Scaduta_EN",
  	  "ERR_GENERIC": "Errore generico accesso archivi_EN",
 	  "ERR_PWD_99": "Errore generico_EN",
 	  "ERR_LOGIN": "Credenziali errate_EN",
 	  "operazioneOk": "Operazione eseguita con successo_EN",
 	  "tuaEmailUtente": "La tua email utente_EN",
 	 "copiaDaAltraFigura": "Copia Da Altra Figura Anagrafica_EN",
 	  "tornaLogin": "Torna alla Login_EN",
 	  "passwordDimenticata": "Credenziali Accesso Dimenticate_EN",
 	  "modificaPassword": "Modifica Password_EN",
 	  "inviaNuoveCredenziali": "Invia Nuove Credenziali_EN",
 	  "cancellaDati": "Cancella Dati_EN",
      "invioEmailTxt": "Procedendo  con questa operazione la proposta di assicurazione sarà inviata all’indirizzo di posta elettronica indicato dal contraente in proposta e all’indirizzo email associato all’utenza Z Semplifico_EN",
      "nessuna": "Nessuna_EN",
      "emailInvioOk": "Email inviata correttamente_EN",
      "elisaWeb": "Elisa Web_EN",
      "moduloSav": "Modulo Sav_EN",
      "relazPaesiBlackList": "Indicare i primi tre paesi con cui si ha relazione_EN",
      "codCognomeNomeColloc": "Codice/Nome Cognome Collocatore_EN", //2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario
      "dirittoObblioOncologico_1": "Diritto all’oblio oncologico (Legge 7 dicembre 2023, n. 193):_EN",
      "dirittoObblioOncologico_2": "L’Assicurato ha il diritto di non fornire informazioni in merito a patologie oncologiche da cui è guarito da più di 10 anni (5 anni se la patologia è insorta prima dei 21 anni). Il Ministero della Salute ha definito per specifiche patologie termini ridotti (_EN",
      "dirittoObblioOncologico_3": "vedasi Allegato 1 al Questionario_EN",
      "dirittoObblioOncologico_4": ")._EN",
      "dirittoObblioOncologico_5": "L’Assicurato, a tutte le domande che si riferiscono a patologie oncologiche di cui al questionario che segue, risponde “NO” quando:_EN",
      "dirittoObblioOncologico_6": "• non ne ha mai sofferto; o_EN",
      "dirittoObblioOncologico_7": "• ne è guarito con terapia/trattamento attivo concluso nei termini sopra indicati, senza episodi di recidiva._EN",
      "allegato1_ALLEGATO": "ALLEGATO 1_EN",
      "allegato1_DECRETO": "DECRETO 22 marzo 2024_EN",
      "allegato1_Elenco": "Elenco di patologie oncologiche per le quali si applicano termini inferiori rispetto a quelli previsti dagli articoli 2, comma 1, 3, comma 1, lettera a), e 4, comma 1, della legge n. 193 del 2023._EN",
      "allegato1_Tabella": "Tabella delle patologie per le quali è previsto un termine ridotto per il maturarsi dell’oblio oncologico rispetto al limite dei dieci anni (o cinque se diagnosi precedente al compimento del 21° anno di età) dalla fine del trattamento o dall’ultimo intervento chirurgico_EN",
      "allegato1_Tabella_Header_1": "Tipo di tumore_EN",
      "allegato1_Tabella_Header_2": "Specificazioni_EN",
      "allegato1_Tabella_Header_3": "Anni dalla fine del trattamento_EN",
      "allegato1_Tabella_R1_C1": "Colon-retto_EN",
      "allegato1_Tabella_R1_C2": "Stadio I, qualsiasi età_EN",
      "allegato1_Tabella_R1_C3": "1_EN",
      "allegato1_Tabella_R2_C1": "Colon-retto_EN",
      "allegato1_Tabella_R2_C2": "Stadio II-III, >21 anni_EN",
      "allegato1_Tabella_R2_C3": "7_EN",
      "allegato1_Tabella_R3_C1": "Melanoma_EN",
      "allegato1_Tabella_R3_C2": ">21 anni_EN",
      "allegato1_Tabella_R3_C3": "6_EN",
      "allegato1_Tabella_R4_C1": "Mammella_EN",
      "allegato1_Tabella_R4_C2": "Stadio I-II, qualsiasi età_EN",
      "allegato1_Tabella_R4_C3": "1_EN",
      "allegato1_Tabella_R5_C1": "Utero, collo_EN",
      "allegato1_Tabella_R5_C2": ">21 anni_EN",
      "allegato1_Tabella_R5_C3": "6_EN",
      "allegato1_Tabella_R6_C1": "Utero, corpo_EN",
      "allegato1_Tabella_R6_C2": "Qualsiasi età_EN",
      "allegato1_Tabella_R6_C3": "5_EN",
      "allegato1_Tabella_R7_C1": "Testicolo_EN",
      "allegato1_Tabella_R7_C2": "Qualsiasi età_EN",
      "allegato1_Tabella_R7_C3": "1_EN",
      "allegato1_Tabella_R8_C1": "Tiroide_EN",
      "allegato1_Tabella_R8_C2": "Donne con diagnosi <55 anni - uomini con diagnosi <45 anni. Esclusi i tumori anaplastici per entrambi i sessi_EN",
      "allegato1_Tabella_R8_C3": "1_EN",
      "allegato1_Tabella_R9_C1": "Linfomi di Hodgkin_EN",
      "allegato1_Tabella_R9_C2": "<45 anni_EN",
      "allegato1_Tabella_R9_C3": "5_EN",
      "allegato1_Tabella_R10_C1": "Leucemie	Acute (linfoblastiche e mieloidi)_EN",
      "allegato1_Tabella_R10_C2": "qualsiasi età_EN",
      "allegato1_Tabella_R10_C3": "5_EN",
      "allegato1_Data": "Data di ultimo aggiornamento (pubblicazione in Gazzetta Ufficiale): 24 aprile 2024_EN",
     }
  },
  de: {
    translation: {
      "IscrizioneARegistroDellePersoneGiuridiche": "Iscrizione A Registro Delle Persone Giuridiche_DE",
      "iscrizioneARegistroImprese": "Iscrizione A Registro Imprese_DE",
      "numeroIscrizione":"Numero Iscrizione_DE",
      "nomeAlbo":"Nome Albo_DE",
      "datiSocietari":"Dati Societari_DE",
      "alboIvass":"Ivass_DE",
      "alboCovip": "Covip_DE",
      "alboConsob": "Consob_DE",
      "alboBancaItalia": "Banca D'Italia_DE",
      "selectedIscrizione":"Indicare se iscrizione:_DE",
      "numeroIscrizioneAlbo": "n.ro Iscrizione_DE",
      "nomeAlbo": "Nome Albo:_DE",
      "seIscrittoAlbo": "Se è iscritto ad un Albo, specificare:_DE",
      "autorita": "Autorità:_DE",
      "numeroIscrizione": "n.ro Iscrizione :_DE",
      "dataIscrizioneAlbo": "Data di iscrizione :_DE",
      "sedeStato": "Sede prevalente attività svolta Stato_DE",
      "statoSedeAttivita": "Sede prevalente attività svolta Stato_DE",
      "numeroMandatoFid": "N. Mandato Fiduciario_DE",
      "pf":"PF_DE",
      "pg":"PG_DE",
      "datiMandatoFiduciario": "Dati Mandato Fiduciario_DE",
      "durataRapportoContrInt": "Da quanto è attivo il rapporto tra contraente e intermediario_DE",
      "flOperazioneCoerente": "L'intermediario attesta che l'operazione è coerente rispetto al profilo del contraente_DE",
      "profiloDiRischio": "Profilo di rischio(per i distributori che attribuiscono al contraente un profilo di rischio)_DE",
      "titolareEffQualifica": "Qualifica:_DE",
      "benDiversoImp": "Se è stato scelto un Beneficiario diverso dall'impresa stessa, specificarne il motivo_DE",
      "titolareEffBeneficiario": "Titolare Effettivo Beneficiario_DE",
      "tabHeaderTitolareEffBenef": "Lista Riepilogo - Titolari Effettivi Beneficiari_DE",
      "titolareEffBenef": "Titolare Effettivo Beneficiario Morte_DE",
      "titolareEffBenef": "Titolare Effettivo Beneficiario Vita_DE",
      "relazioneTitolareContrMorte": "Relazione Contraente/Titolare effettivo_DE",
      "relazioneTitolareContrVita": "Relazione Contraente/Titolare effettivo_DE",
      "corrispondenzaDiversoResidenza": "Indirizzo di corrispondenza diverso da residenza /sede legale:_DE",
      "corrispondenzaIntestatoContraente": "Indirizzo di corrispondenza intestato al contraente:_DE",
      "intermediarioBancario": "In caso di sottoscrizione presso intermediario Bancario, se origine diversa da Reimpiego, si chiede di specificare il tempo intercorso tra l'afflusso della provvista presso la banca e la data pagamento premio_DE",
      "general.classeBeneficiari": "Classe Beneficiari_DE",
      "general.motivoDellaRelazione": "Motivo della relazione_DE",
      "general.altro": "Altro_DE",
      "general.AltroAttPrevAttSpecificare": "Altro (Specificare):_DE",
      "general.seAltroSpecificare":"Se altro, specificare:_DE",
      "general.luogo": "Luogo_DE",
      "general.data": "Data_DE",
      "general.cognome": "Cognome_DE",
      "general.nome": "Nome_DE",
      "general.nominativo": "Nominativo_DE",
      "general.collocatore": "Collocatore_DE",
      "general.operazioneInCorso": "Operazione in Corso...._DE",
      "general.verificaCodFiscInCorso": "Verifica Codice Fiscale in Corso....DE",
      "contraente": "Contraente_DE",
      "assicurato": "Assicurato_DE",
      "esecutore": "Soggetto Terzo_DE", 
      "soggettoTerzoError": "E' obbligatorio definire il soggetto terzo_DE",
      "beneficiarioVita": "Beneficiario Vita_DE",
      "beneficiarioMorte": "Beneficiario Morte_DE",
      "benefVita": "Benef. Vita_DE",
      "benefMorte": "Benef. Morte_DE",
      "beneficiarioCedola": "Beneficiario Cedola_DE",
      "beneficiarioGenerico": "Beneficiario Generico_DE",
      "rappresentanteLegale": "Rappresentante Legale_DE",
      "titolareEffettivo": "Titolare Effettivo Del Contraente_DE",
      "referenteTerzo": "Referente Terzo_DE",
      "referenteTerzoFacoltativo": "Referente Terzo (Facoltativo)_DE",
      "dettaglioContraente": "Dettaglio Contraente_DE",     
      "ragioneSociale": "Ragione Sociale_DE",
      "dataCostituzione": "Data di Costituzione_DE",  
      "sesso": "Sesso_DE",
      "maschio": "Maschio_DE",
      "femmina": "Femmina_DE",
      "codiceFiscale": "Codice Fiscale_DE",
      "partitaIVA": "Partita IVA_DE",
      "percBeneficiario": "% Beneficiario_DE",
      "percOwnership": "% di Ownership_DE",
      "dataNascita": "Data di Nascita_DE",
      "statoNascita": "Stato di Nascita_DE",     
      "provinciaNascita": "Provincia di Nascita_DE",  
      "comuneNascita": "Comune di Nascita_DE",  
      "esisteEsecutore": "Esiste Soggetto Terzo_DE",
      "esisteReferenteTerzo": "Esiste Referente Terzo_DE",
      "si": "SI_DE", 
      "no": "NO_DE",
      "relazContrEsecut": "Relazione Contraente/Soggetto Terzo_DE", 
      "relazioneContrTitEff": "Relazione Contraente/Titolare effettivo_DE",
      "relazContrAss": "Relazione Contraente/Assicurato_DE",
      "relazContrBenefVita": "Relazione tra Contraente e Beneficiario caso vita_DE",
      "relazContrBenefMorte": "Relazione tra Contraente e Beneficiario caso morte_DE",
      "motivoDesignazione": "Motivo della designazione di tale Assicurando_DE",
      "tipoRappresentanza": "Indicare il tipo di Rappresentanza_DE",
      "nucleoFam": "Nucleo familiare_DE",     
      "ilPresenteEsecutore": "Il presente soggetto terzo è munito di rappresentaza?_DE", 
      "munitoDelega": "SI_DE",  
      "privoDelega": "NO_DE",
      "datiGenerali":"Dati Generali_DE",
      "datiGeneraliEsecutore": "Dati Generali del Soggetto terzo_DE",
      "datiDiResidenza":"Dati Residenza_DE",
      "datiDiResidenzaGiuridico":"Dati della Sede Legale_DE",
      "datiDiResidenzaEsecutore": "Dati Residenza del Soggetto terzo_DE",
    //2020-12-01 PFD: SAV 'TO BE'      "datiDiRecapito":"Dati di Recapito_DE",
      "indirizzoDiCorrispondenza":"Indirizzo di Corrispondenza_DE",
      "datiDiRecapitoEsecutore": "Dati di Recapito del Soggetto terzo_DE",
      "datiDiDomicilioEsecutore": "Dati di Domicilio del Soggetto terzo_DE",
      "datiDiDomicilioTitolareEff": "Dati di Domicilio del Titolare Effettivo_DE",
      "datiDiDomicilioContrFis": "Dati di Domicilio_DE",
      "datiDiDomicilioRappLegale": "Dati di Domicilio del Rappresentante Legale_DE",
      "domicilioDiversoResidenza": "Domicilio diverso da Residenza_DE",
      "datiAntiriciclaggio":"Dati Antiriciclaggio_DE",
      "datiAntiriciclaggioRappLegale":"Dati Antiriciclaggio del Rappresentante Legale_DE",
      "datiAntiriciclaggioTitolareEff":"Dati Antiriciclaggio del Titolare Effettivo_DE",    
      "datiAntiriciclaggioEsecutore": "Dati Antiriciclaggio del Soggetto terzo_DE",  
      "datiConsensoComunicazioneElettronica":"Consenso alla trasmissione della documentazione in formato elettronico_DE",
      "consensoComunicazioneElettronica":"Consenso alla trasmissione in formato elettronico della documentazione relativa al contratto_DE",
      "stato":"Stato_DE",
      "provincia":"Provincia_DE",
      "comune":"Comune_DE",
      "toponimo":"Via/Piazza_DE",
      "indirizzo":"Indirizzo_DE",
      "numCivico":"Num.Civico_DE",
      "cap":"CAP_DE",
      "presso": "Presso_DE",
      "cittadinanza1":"Cittadinanza 1_DE",
      "cittadinanza2":"Cittadinanza 2_DE",
      "numTelefono":"Telefono_DE",
      "cellulare":"Cellulare_DE",
      "datiAttivitaEconomica":"Dati Attività Economica_DE",
      "datiAttivitaEconomicaRappLegale": "Dati Attività Economica Rappresentante Legale_DE",
      "datiAttivitaEconomicaEsecutore": "Dati Attività Economica del Soggetto terzo_DE",
    //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  "datiSituazioneFinanziaria": "Dati Situazione Finanziaria (da non compilare per il prodotto Taboo)_DE",
      "datiSituazioneFinanziaria": "Dati Situazione Finanziaria",
      "fonteReddito": "Fonte Del Reddito_DE",
      "redditoAnnuo": "Reddito Annuo_DE",
      "primaCasaContraente": "Prima Casa Di Proprieta_DE",
      "altriImmobiliContraente": "Altri Immobili Di Proprieta_DE",
      "patrimonioFinanziario": "Patrimonio Finanziario Inclusa La Liquidita_DE",
      "impegnoFinanziario": "Impegni Finanziari Mensili_DE",
      "strumentiMercMon": "Strumenti Mercato Monetario_DE",
      "strumentiObbl": "Strumenti Obbligazionari_DE",
      "strumentiAzionari": "Strumenti Azionari / Fondi Investimento_DE",
      "strumentiAssicurativi": "Strumenti Assicurativi_DE",
      "strumentiComplessi": "Strumenti Complessi (Es. Fondi Alt.)_DE",
      //2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP  "datiSituazioneFinanziariaGiuridico": "Dati Situazione Finanziaria (da non compilare per il prodotto Taboo)_DE",
      "datiSituazioneFinanziariaGiuridico": "Dati Situazione Finanziaria",
      "sediSocContrGiuridico": "Sedi Della Società di Proprietà_DE",
    //2020-12-01 PFD: SAV 'TO BE'      "inveImmoContrGiuridico": "Investimenti Immobiliari, indicare se:_DE",
      "altriImmobiliContraenteGiuridico": "Altri Immobili Di Proprietà_DE",
      "fatturatoAnnuo": "Indicare Il Fatturato Annuo:",
      "prevalAttSvolta":"Att. Preval. Svolta_DE",
      "provAttPreval":"Provincia Att. Preval._DE",
      "comuneAttPreval":"Comune Att. Preval._DE",
      "capAttPreval":"CAP Att. Preval._DE",
      "sae":"SAE_DE",
      "ateco":"ATECO_DE",
      "titoloStudio": "Istruzione_DE",
      "altroTitoloStudio": "Descrizione Titolo di Studio_DE",
      "datiFiscali":"Dati Fiscali_DE",
      "datiFiscaliTitolareEff":"Dati Fiscali del Titolare Effettivo_DE",
      "tasseStatiUniti":"Paga le tasse negli Stati Uniti d'America/US Territories_DE",
      "tinGiin":"TIN / GIIN_DE",
      "residenzaFiscale":"Ha la Residenza Fiscale in uno Stato diverso dall'Italia_DE",
      "statoResidenzaFiscale1":"Stato Residenza Fiscale 1_DE",
      "statoResidenzaFiscale2":"Stato Residenza Fiscale 2_DE",
      "nifResidenzaFiscale1":"NIF Residenza Fiscale 1_DE",
      "nifResidenzaFiscale2":"NIF Residenza Fiscale 2_DE",
      "documentoRiconoscimento":"Documento di Riconoscimento_DE",
      "tipoDocumento":"Tipo Documento_DE",
      "numDocumento":"Num. Documento_DE",
      "enteRilascio":"Ente Rilascio_DE",
      "dataRilascio":"Data Rilascio_DE",
      "dataScadenza":"Data Scadenza_DE",
      "statoRilascio":"Stato Rilascio_DE",
      "provRilascio":"Provincia Rilascio_DE",
      "comuneRilascio":"Comune Rilascio_DE",
      "persEspostoPoliticamente":"Persona Esposta Politicamente_DE",
      "caricaPubblicaNoPEP":"Altre cariche pubbliche diverse da PEP_DE",
      "scopoNaturaRapportoOrigineFondi":"Scopo e Natura del Rapporto e Origine dei Fondi_DE",
      "scopoNaturaRapportoOrigineFondiTitolareEff":"Scopo e Natura del Rapporto e Origine dei Fondi del Titolare Effettivo_DE",
      "scopoNatura":"Scopo e Natura_DE",
      "stipulatoPerConto": "Il contratto è stipulato per conto proprio o per conto dell’assicurato (Rif. Contratto per conto altrui art. 1891 codice civile):_DE",
      "scopoNaturaContratto": "Ricerca tempo intercorso tra afflusso provvista e data operazione_DE",
    //2020-12-01 PFD: SAV 'TO BE'      "labelRipartizioneInvestimenti": "Ripartizione indicativa degli investimenti finanziari (dati facoltativi)_DE",
      "selezioneIntermediarioBancario": "Ricerca tempo intercorso tra afflusso provvista e la data pagamento premio_DE",
      "selezioneStatoProvenienzaSomme": "Specificare il paese di provenienza delle somme (se diverso da Italia)_DE ",
      "labelOrigineFondi": "Specificare l'origine dei fondi: (è possibile selezionare fino a due risposte)_DE",
      "soggettiDaTutelare": "Soggetti da Tutelare_DE",
      "origineFondi":"Origine Fondi_DE",
      "comboRicerca": "Ricerca_DE",
      "datiAnagrafici": "Dati Anagrafici_DE",
      "indEmail": "Ind. Email_DE",
      "indEmailCert": "Ind. Email Certificata_DE",
      "presentiTitolariEffettivi": "Sono presenti Titolari Effettivi_DE",
      "entePubblico": "Ente Pubblico_DE",
      "societaQuotata": "Società Quotata_DE",
      "commerciale": "Commerciale_DE",
      "fdoPensione": "F.do Pensione_DE",
      "fiduciaria": "Fiduciaria_DE",
      "intermediarioFinanziario": "Intermediario Finanziario_DE",
      "contraenteAssicurato": "Contraente / Assicurato_DE", 
      "assicuratoUgualeContraente": "L'Assicurato coincide con il Contraente_DE",
      "valutazioneAiSensiLgs": "Valutazione ai sensi dell’D. Lgs. 231/2007  e successive modifiche_DE",
/*20230313 PFD - MISTEP-IFA: sostituito radioCompDb con chiamata al servizio.
      "reticenzaFonireInfo": "Reticenza nel fonire le informazioni richieste_DE",
      "pocaTrasparenzaComp": "Poca trasparenza della compagine societaria al fine di identificare il titolare effettivo_DE",
      "inusualitaTransazione": "Inusualità della transazione e/o delle modalità operative o della tempistica_DE",
      "interposizioneTerzi": "L’interposizione di terzi senza alcuna motivazione commerciale apprezzabile_DE",
      "comportamentoNonDenotaAnomalie": "Comportamento che non denota anomalie_DE", */
      "dichiarazioniDatiSoggetto": "Dichiarazioni e dati del soggetto Incaricato al collocamento_DE",
      "regioneAttivita": "Regione Attività_DE",
      "codiceIntermediario": "Codice Intermediario_DE",
      "codiceRUI": "Codice RUI_DE",
      "altreFigure": "Altre Figure_DE",
      "caratteristicheContratto": "Caratteristiche Contratto_DE",
      "consenso": "Consenso_DE",
      "consensoTrattamentoDati": "Consenso al trattamento dei dati_DE", 
      "testo.privacy1": "1. Dichiara/no di prendere atto che i suoi dati personali  ivi inclusi i dati relativi alla salute - saranno trattati dalla Società per le finalità di cui al punto 2 a) dellInformativa medesima (finalità contrattuali e di legge).Inoltre, il Contraente, qualora soggetto diverso dall'Assicurando, dal Beneficiario e dal Referente Terzo, dichiara di provvedere a rendere noti i contenuti dell'Informativa all'Assicurando/Beneficiario/Referente Terzo alla prima occasione di contatto con quest'ultimo/i._DE",
      "testo.privacy2": "2. Per finalità di marketing, invio di offerte promozionali, iniziative commerciali dedicate, inviti alla partecipazione a concorsi a premi, materiale pubblicitario e vendita di prodotti o servizi della Società o di altre Società del Gruppo Zurich Insurance Group Ltd (tramite strumenti automatizzati quali ad esempio fax, sms, posta elettronica etc e strumenti tradizionali quali posta e telefono) nonché per consentire alla Società di condurre ricerche di mercato._DE",
      "testo.privacy3": "3. Pr consentire alla Società di effettuare rilevazioni statistiche, al fine di migliorare i propri prodotti e servizi._DE",
      "testo.privacy4": "4. Per la comunicazione degli stessi a soggetti terzi come indicati al punto 2 lett. d) dell'Informativa, i quali potranno a loro volta trattarli per loro proprie finalità di marketing, invio di comunicazioni commerciali e vendita diretta tramite posta, posta elettronica, telefono, fax e qualsiasi altra tecnica di comunicazione a distanza, automatizzata e non automatizzata._DE",      
      "dichiarazioniConsensi": "Dichiarazioni Consensi_DE",
      "dichiarazioneIntermediario": "Dichiarazioni Intermediario_DE",
      "questionari": "Questionari_DE",
      "stampaProposta": "Stampa Proposta_DE",
      "convalidaStampaDefinitiva": "Convalida e Stampa Definitiva_DE",      
      "nuovaProposta": "Nuova Proposta_DE",
      "questionario": "Questionario_DE",    
      "Risparmio": "Risparmio_DE",
      "Previdenza": "Previdenza_DE",
      "Protezione": "Protezione_DE",
      "Investimento": "Investimento_DE",   
      "premio": "Premio_DE",    
      "ripartizionePremio": "Ripartizione Premio_DE",    
      "opzioniContrattuali": "Opzioni Contrattuali_DE",    
      "modalitaPagamento": "Modalità di Pagamento_DE",  
      "premioUnico": "Premio Unico_DE",
      "importoPremioUnicoIniziale": "(Importo del premio Unico Iniziale minimo Euro 20.000,00)_DE", 
      "classePremiPotenziale": "Classe di premi Potenziale_DE", 
      "variazioneCostiStandard": "Hai variato le condizioni standard del prodotto (es. commission sacrifice, bonus al cliente, special deals, deroghe costi) ?_DE",
      "classeA": "Classe A - per previsone di versamenti futuri fino a Euro 749.999,99_DE", 
      "classeB": "Classe B - per previsone di versamenti futuri da Euro 750.0000,00 a 1.499.999,99_DE", 
      "classeC": "Classe C - per previsone di versamenti futuri da Euro 1.500.0000,00 a 2.499.999,99_DE", 
      "classeD": "Classe D - per previsone di versamenti futuri a partire da  Euro 2.500.0000,00_DE", 
      "contraenteDichiaraPagatore": "Il contraente dichiara che il premio è pagato da_DE", 
      "iban": "IBAN_DE", 
      "banca": "Banca_DE", 
      "agenzia": "Agenzia_DE", 
      "numeroAssegno1": "Numero Assegno 1_DE",
      "numeroAssegno2": "Numero Assegno 2_DE",
      "contoCointestato": "Conto Cointestato_DE",
      "nomeCognomeCointestatario": "Cognome e Nome del Cointestatario_DE",
      "relazPagatContr": "Relazione Pagatore/Contraente_DE",
      "relazContrCoint": "Relazione Contraente/Cointestatario_DE",
      "ripartizioneNonValida": "La somma delle ripartizioni finanziarie deve essere minore o uguale al patrimonio finanziario_DE",
      "errorPensione": "Attività precedente al pensionamento non congruente_DE",
      "nSottorubrica": "N. Sottorubrica_DE",
      "intestatario": "Cognome e Nome dell'intestatario_DE",
      "paeseBonifico": "Paese_DE",
      "motivoContoEstero": "Motivo del conto Estero_DE",
      "codiceSwift": "Codice SWIFT_DE",
      "intestatarioBonificoEstero": "Cognome e Nome dell'intestatario_DE",
      "intestatarioCarta": "Cognome e Nome dell'intestatario_DE",
      "polizzaPolizze": "Polizza / Polizze_DE",
      "modPagAltro": "Specificare la modalità di pagamento_DE",
      "selezionareOpzioniContrattuali": "Selezionare una delle seguenti opzioni contrattuali. Non è possibile selezionare più opzioni su singolo contratto_DE",
      "selezionarePianoSpostamento": "Selezionare una delle seguenti opzioni del piano di spostamento. Non è possibile selezionare più opzioni su singolo contratto_DE",
      "pianoSpostamentoAutomatico": "PIANO SPOSTAMENTO AUTOMATICO_DE",
      "allocazioneTarget": "Allocazione Target_DE",
      "pianoErogazioniPeriodiche": "Piano Erogazioni Periodiche_DE",
      "takeProfit": "Take Profit_DE",
      "lifeCycle": "Life Cycle_DE",
      "freqPrestazione": "Frequenza della Prestazione_DE",
      "durata": "Durata_DE",
      "percentuale": "Percentuale_DE",
      "general.perc": "Perc. %_DE",
      "percentualeObiettivo": "Percentuale Obiettivo_DE",
      "legendaPianoErogazioniPeriodiche": "Il piano delle erogazioni periodiche è attivabile se la parte di premio destinata alla linea guidata/libera è pari almeno a 30.000 €_DE",
      "legendaTakeProfit": "L’opzione take profit è attivabile a condizione che il premio versato sia pari almeno a 30.000 €_DE",
      "legendaLifeCycle": "L’opzione life cycle è attivabile solo per la linea guidata Swing e per assicurati di età inferiore ai 55 anni_DE",
      "allocazione": "Allocazione_DE",
      "allocazioneMaxGestSep": "Allocazione massima in Gestione Separata così come indicato nelle condizioni contrattuali_DE",
      "infoRibalanciamento": "In caso di Linea Libera indicare la soluzione con o senza Ribilanciamento:_DE",
      "ribilanciamento": "Ribilanciamento_DE",
      "codiceIsin": "Codice ISIN_DE",
      "nomeFondo": "Nome Fondo_DE",
      "percInvest": "% Inves.to_DE",
      "note": "Note_DE",
      "ordina": "Ordina_DE",
      "datiRiepilogo" : 'Dati Riepilogo_DE',
      "tabHeaderBenMorte" : 'Lista Riepilogo - Beneficiari Caso Morte_DE',
      "tabHeaderBenVita" : 'Lista Riepilogo - Beneficiari Caso Vita_DE',
      "tabHeaderTitEff" : 'Lista Riepilogo - Titolari Effettivi_DE',
      "aggiungiBenefic": "Aggiungi Benef._DE",
      "nuovoBenefic": "Nuovo Benefic._DE",
      "nuovoTitEff": "Nuovo Titolare Eff_DE",
      "noteFondi": "Nel caso in cui si scelga la linea libera, l’allocazione del premio può essere destinata da un minimo di 2 ad un massimo di 10 fondi con una % minima di investimento pari a 5%_DE",
      "noteFondi2": "La somma delle % deve essere pari a 100%_DE",
      "totale": "TOTALE_DE",
      "questionarioIvass": "Questionario IVASS_DE",
      "questionarioSanitario": "Questionario SANITARIO_DE",
      "questionarioSanitarioSemplificato": "Questionario SANITARIO SEMPLIFICATO_DE",
      "statoResidenza":"Stato di Residenza_DE",
      "provinciaResidenza":"Provincia di Residenza_DE",
      "comuneResidenza":"Comune di Residenza_DE",
      "utente": "Utente_DE",
      "password": "Password_DE",
      "tipoSocieta": "Tipo Società_DE",
      "visuraCamerale" : "Visura Camerale_DE",
      "allega" : "Allega:_DE",
      "paesiBlackList": "L’attività di impresa include relazioni con i seguenti paesi ?_DE",
      "entitaFinanzPassiva": "Il contraente è un’entità finanziaria passiva così come definita dal Decr. Min.18/10/2015 in attuazione della legge 18/06/2015 art 1-g e rif.successivi ?_DE",
      "accountDimenticato": "Account dimenticato?_DE",
	  "credenzialiDimenticate": "Hai dimenticato le tue credenziali di accesso?_DE",	
      "nuovaPassword": "Nuova Password_DE",
      "premioCapitaleAssicurato": "Premio & Capitale Assicurato_DE", 
      "tariffa": "Tariffa_DE",
      "edizioneModello": "Edizione Modello_DE",
      "durataPagamentoPremi": "Durata Pag.Premi_DE",
      "capitaleAssicurato": "Capitale Assicurato_DE",
      "malattiaGrave": "Malattia Grave_DE",
      "capitaleAssicuratoMalattiaGrave": "Capitale Assicurato Malattia Grave_DE",
      "fasceCapitaleAmmesse": "(fasce di capitale ammesse: Euro 50.000,00 Euro 100.000,00 Euro 150.000,00)_DE", 
      "complementareInfortuni": "Complementare Infortuni_DE",
      "capitaleMassimoAssicurabile": "(capitale massimo assicurabile Euro 260.000,00)_DE", 
      "frazionamento": "Frazionamento_DE",       
      "infoLineeMultinvest": "Selezionare soltanto una delle linee Multinvest Extra. Non è possibile abbinare una linea guidata ad una linea libera_DE",
      "infoLineeUnitLinked": "Selezionare soltanto una delle linee guidate. Non è possibile abbinare una linea guidata ad una linea libera_DE",
      "lineeGuidate": "Linee Guidate_DE",
      "isChangedFromSemplificatoAlert": "Devi compilare il questionario Sanitario_DE",
      "questionarioAdeguato": "Questionario Adeguato_DE",
      "questionarioNonAdeguato": "Questionario Non Adeguato_DE",
//20210615      "premioRata": "Premio di rata_DE",
      "premioRataVersSottoscrizione": "Premio di rata versato all'atto della sottoscrizione",
      "premioAnnuo": "Premio annuo di tariffa_DE",
      "100": "Questionario anamnestico non idoneo - tariffa non selezionata_DE",
      "101": "Questionario anamnestico non idoneo_DE",               
      "102": "Questionario anamnestico non idoneo - rapporto peso altezza_DE",                            
   	  "103": "Questionario anamnestico non idoneo - valori pressori fuori norma_DE",                       
   	  "104": "Questionario anamnestico non idoneo - sigarette fumate >= 35_DE", 
   	  "113": "Questionario anamnestico non idoneo - richiesta sovrappremi automatici_DE",                  
   	  "212": "Questionario anamnestico non idoneo - copertura esclusiva mutuo o finanziamento_DE",
   	  "307": "Questionario non compilato_DE",
       "questionaIdoneo": "Questionario anamnestico compilato_DE", 
       "questionaIdoneoSempl": "Questionario anamnestico semplificato compilato_DE",      
   	  "beneficiarioEsteso": "Beneficiario Esteso_DE",  
   	  "gestioneUtenze": "Gestione Utenze_DE",
   	  "nuovaUtenza": "Nuova Utenza_DE", 
 	  "modificaUtenza": "Modifica Utenza_DE", 
   	  "invioEmail": "Invio Email_DE",
   	  "invia": "Invia_DE",
      "datiValidi": "Salvataggio dati eseguito correttamente_DE",
      "massimoTitEff": "Attenzione il numero massimo dei Titolari Effettivi è 4_DE",
      "datiNonValidi": "Completare i dati richiesti (*) o evidenziati con errore_DE",
   	  "errTotPercBenef": "La Percentuale Totale dei beneficiari deve essere uguale a 100%_DE",
       "errTotPercTitEff": "La Percentuale Totale dei titolari non puo' essere superiore a 100%_DE",
       "errorePercTotaleAllocazione": "La somma tra la percentuale destinata alla Gestione Separata e la percentuale destinata alla Linea di Investimento (guidata o libera) deve essere pari a 100%_DE",
   	  "errorePercTotaleAllocazioneUL": "La percentuale destinata alla Linea di Investimento (guidata o libera) deve essere pari a 100%_DE",
   	  "errorePercTotaleGestSep": "La percentuale destinata alla Gestione Separata non può superare il valore indicato nelle condizioni contrattuali_DE",
   	  "errorePercTotaleFondo": "La somma delle percentuali di investimento sui singoli fondi deve essere 100%_DE",
       "erroreFiduciante": "Almeno un titolare effettivo deve avere la qualifica di Fiduciante_DE",
       "codiceConsulente": "Codice Consulente_DE",
   	  "profilo": "Profilo_DE",
   	  "dataCreazione": "Data Creazione_DE",
   	  "mandato": "Mandato_DE",
   	  "piazza": "Piazza_DE",
   	  "dataUltimoAccesso": "Data Ultimo Accesso_DE",
   	  "attezioneStampa": "ATTENZIONE: Procedendo con questa operazione i dati inseriti saranno convalidati e non potranno più essere modificati_DE",
	  "seguiSempliciOperazioni": "Segui quindi queste semplici operazioni:_DE",
   	  "sempliceOperazioni_1": "1. Stampa e fai sottoscrivere la proposta ed i suoi allegati al cliente_DE", 
   	  "sempliceOperazioni_2": "2. Spedisci tutta la documentazione firmata in originale   presso gli uffici Zurich_DE",
//2020-02-25 PFD: PROMETEIA II - PRODOTTI NO IBIP   	  "sempliceOperazioni_3": "3. Al fine di ottemperare a quanto previsto dalla normativa antiriciclaggio si richiede di compilare il modulo SAV la cui compilazione è obbligatoria per tutti i prodotti a meno di Taboo_DE",
   	  "anteprimaStampa": "Anteprima di Stampa_DE",
   	  "anteprima": "Anteprima_DE",
   	  "filtra": "Filtra_DE",
   	  "dataBlocco": "Data Blocco_DE",
	  "dataRevoca": "Data Revoca_DE,",
	  "statoUtenza": "Stato Utenza_DE",
	  "dataUltimaVariazione": "Data Ultima Variazione_DE",
	  "revoca": "Revoca_DE",
   	  "modifica": "Modifica_DE",
   	  "sblocca": "Sblocca_DE",
   	  "riattiva": "Riattiva_DE",
   	  "salva": "Salva_DE",
   	  "salvaOk": "Salvataggio effettuato correttamente_DE",
 	  "salvako": "Errore durante il salvataggio_DE",
 	  "statoUtenteA": "ATTIVA_DE",
 	  "statoUtenteP": "PENDENTE_DE",
  	  "statoUtenteR": "REVOCATA_DE",
  	  "statoUtenteB": "BLOCCATA_DE",
  	  "statoUtenteS": "SCADUTA_DE",
  	  "ricercaKo": "Nessun risultato trovato per i criteri di ricerca inseriti_DE",
  	  "tornaRicerca": "Torna alla Ricerca_DE",
  	  "logout": "Logout_DE",
  	  "areaProdotti": "Area Prodotti_DE",
  	  "tuoIdUtente": "Il tuo ID utente_DE",
 	  "IdUtente": "ID utente_DE",
 	  "tuaPassword": "La tua Password_DE",
 	  "accedi": "Accedi_DE",
 	  "tuaPasswordNuova": "La tua nuova Password_DE",
  	  "confermaPassword": "Conferma la Password_DE",
  	  "ERR_PWD_01": "Nuova password o conferma password non valorizzate_DE",
 	  "ERR_PWD_02": "Nuova password e conferma password non coincidono_DE",
 	  "ERR_PWD_03": "Nuova password deve differire dalla precedente_DE",
 	  "ERR_PWD_04": "Vecchia password non corrisponde_DE",
 	  "ERR_PWD_05": "Nuova password non formalmente corretta_DE",
  	  "ERR_PWD_05_DET": "Lunghezza minima 8 caratteri, deve contenere almeno una lettera maiuscola, una minuscola, un numero ed un carattere speciale_DE",
  	  "ERR_ULT_TEN": "Credenziali errate: resta un tentativo poi l’utenza verrà bloccata_DE",
 	  "ERR_UTE_BLOCCATO": "Credenziali errate: account bloccato, contattare l’amministratore del sistema oppure selezionare l’azione nuova password_DE",
 	  "ERR_UTE_REVOCATO" : "Account revocato, contattare l’amministratore del sistema_DE",
 	  "ERR_EMAIL_NOT_FOUND": "Indirizzo e-mail non valido_DE",
 	  "ERR_EMAIL_FOUND": "Indirizzo e-mail già presente_DE",
 	  "ERR_USER_NOT_FOUND": "Utente non valido_DE",
 	  "INF_SCAD_PWD": "Password in scadenza, vuoi modificarla?_DE",
 	  "ERR_SCAD_PWD": "Password Scaduta_DE",
  	  "ERR_GENERIC": "Errore generico accesso archivi_DE",
 	  "ERR_PWD_99": "Errore generico_DE",
 	  "ERR_LOGIN": "Credenziali errate_DE",
 	  "operazioneOk": "Operazione eseguita con successo_DE",
 	  "tuaEmailUtente": "La tua email utente_DE",
 	  "copiaDaAltraFigura": "Copia Da Altra Figura Anagrafica_DE",
 	  "tornaLogin": "Torna alla Login_DE",
 	  "passwordDimenticata": "Credenziali Accesso Dimenticate_DE",
 	  "modificaPassword": "Modifica Password_DE",
 	  "inviaNuoveCredenziali": "Invia Nuove Credenziali_DE",
 	  "cancellaDati": "Cancella Dati_DE",
      "invioEmailTxt": "Procedendo  con questa operazione la proposta di assicurazione sarà inviata all’indirizzo di posta elettronica indicato dal contraente in proposta e all’indirizzo email associato all’utenza Z Semplifico_DE",
      "nessuna": "Nessuna_DE",
      "emailInvioOk": "Email inviata correttamente_DE",
      "elisaWeb": "Elisa Web_DE",
      "moduloSav": "Modulo Sav_DE",
      "relazPaesiBlackList": "Indicare i primi tre paesi con cui si ha relazione_DE",
      "codCognomeNomeColloc": "Codice/Nome Cognome Collocatore_DE", //2022-10-22 PFD: Release GLIT_2022_016 - Unificato dati Intermediario
      "dirittoObblioOncologico_1": "Diritto all’oblio oncologico (Legge 7 dicembre 2023, n. 193):_DE",
      "dirittoObblioOncologico_2": "L’Assicurato ha il diritto di non fornire informazioni in merito a patologie oncologiche da cui è guarito da più di 10 anni (5 anni se la patologia è insorta prima dei 21 anni). Il Ministero della Salute ha definito per specifiche patologie termini ridotti (_DE",
      "dirittoObblioOncologico_3": "vedasi Allegato 1 al Questionario_DE",
      "dirittoObblioOncologico_4": ")._DE",
      "dirittoObblioOncologico_5": "L’Assicurato, a tutte le domande che si riferiscono a patologie oncologiche di cui al questionario che segue, risponde “NO” quando:_DE",
      "dirittoObblioOncologico_6": "• non ne ha mai sofferto; o_DE",
      "dirittoObblioOncologico_7": "• ne è guarito con terapia/trattamento attivo concluso nei termini sopra indicati, senza episodi di recidiva._DE",
      "allegato1_ALLEGATO": "ALLEGATO 1_DE",
      "allegato1_DECRETO": "DECRETO 22 marzo 2024_DE",
      "allegato1_Elenco": "Elenco di patologie oncologiche per le quali si applicano termini inferiori rispetto a quelli previsti dagli articoli 2, comma 1, 3, comma 1, lettera a), e 4, comma 1, della legge n. 193 del 2023._DE",
      "allegato1_Tabella": "Tabella delle patologie per le quali è previsto un termine ridotto per il maturarsi dell’oblio oncologico rispetto al limite dei dieci anni (o cinque se diagnosi precedente al compimento del 21° anno di età) dalla fine del trattamento o dall’ultimo intervento chirurgico_DE",
      "allegato1_Tabella_Header_1": "Tipo di tumore_DE",
      "allegato1_Tabella_Header_2": "Specificazioni_DE",
      "allegato1_Tabella_Header_3": "Anni dalla fine del trattamento_DE",
      "allegato1_Tabella_R1_C1": "Colon-retto_DE",
      "allegato1_Tabella_R1_C2": "Stadio I, qualsiasi età_DE",
      "allegato1_Tabella_R1_C3": "1_DE",
      "allegato1_Tabella_R2_C1": "Colon-retto_DE",
      "allegato1_Tabella_R2_C2": "Stadio II-III, >21 anni_DE",
      "allegato1_Tabella_R2_C3": "7_DE",
      "allegato1_Tabella_R3_C1": "Melanoma_DE",
      "allegato1_Tabella_R3_C2": ">21 anni_DE",
      "allegato1_Tabella_R3_C3": "6_DE",
      "allegato1_Tabella_R4_C1": "Mammella_DE",
      "allegato1_Tabella_R4_C2": "Stadio I-II, qualsiasi età_DE",
      "allegato1_Tabella_R4_C3": "1_DE",
      "allegato1_Tabella_R5_C1": "Utero, collo_DE",
      "allegato1_Tabella_R5_C2": ">21 anni_DE",
      "allegato1_Tabella_R5_C3": "6_DE",
      "allegato1_Tabella_R6_C1": "Utero, corpo_DE",
      "allegato1_Tabella_R6_C2": "Qualsiasi età_DE",
      "allegato1_Tabella_R6_C3": "5_DE",
      "allegato1_Tabella_R7_C1": "Testicolo_DE",
      "allegato1_Tabella_R7_C2": "Qualsiasi età_DE",
      "allegato1_Tabella_R7_C3": "1_DE",
      "allegato1_Tabella_R8_C1": "Tiroide_DE",
      "allegato1_Tabella_R8_C2": "Donne con diagnosi <55 anni - uomini con diagnosi <45 anni. Esclusi i tumori anaplastici per entrambi i sessi_DE",
      "allegato1_Tabella_R8_C3": "1_DE",
      "allegato1_Tabella_R9_C1": "Linfomi di Hodgkin_DE",
      "allegato1_Tabella_R9_C2": "<45 anni_DE",
      "allegato1_Tabella_R9_C3": "5_DE",
      "allegato1_Tabella_R10_C1": "Leucemie	Acute (linfoblastiche e mieloidi)_DE",
      "allegato1_Tabella_R10_C2": "qualsiasi età_DE",
      "allegato1_Tabella_R10_C3": "5_DE",
      "allegato1_Data": "Data di ultimo aggiornamento (pubblicazione in Gazzetta Ufficiale): 24 aprile 2024_DE",
    }
  }
};
